import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getCountries } from '@dep/common/countries';
import { IContact, RoleRight } from '@dep/common/interfaces';
import { UserStatus } from '@dep/common/user-api/types/user.type';
import { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

import { ModalComponentOptions } from '@dep/frontend/blocks/info-modal/info-modal.component';
import { UserModel } from '@dep/frontend/models/user';
import { PopupGenericConfirmationComponent } from '@dep/frontend/popups/generic-confirmation/generic-confirmation.component';
import { PopupGenericMessageComponent } from '@dep/frontend/popups/generic-message/generic-message.component';
import { getAllLanguages } from '@dep/frontend/services/countries';
import { LocationsService } from '@dep/frontend/services/locations.service';
import { PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { TranslationService } from '@dep/frontend/services/translation.service';
import { UserService } from '@dep/frontend/services/user.service';
import { UsersService } from '@dep/frontend/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-details',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.scss'],
})
export class UsersDetailsComponent implements OnInit, OnDestroy {
  public model: UserModel = new UserModel(-1, '', '', '', '', '', '', '', '-', '-', '', UserStatus.Pending, null, null, []);
  public userId?: number;
  public contacts?: IContact[];
  public canEdit = false;
  public userDeleteInProgress = false;
  public isSsoUser?: boolean;
  public mercedesBenzId?: string;
  private routeParamsSub$?: Subscription;
  public userPoolId = environment.config.cognito.userPoolId;
  public readonly UserStatus = UserStatus;
  public readonly RoleRight = RoleRight;

  public selectCountries: NgOption[] = [];
  public selectLanguages: NgOption[] = [];
  public selectStatus: NgOption[] = [];
  public selectOrgaentities?: NgOption[];
  public selectRoles?: NgOption[];

  public isOrgaentitiesLoading = false;
  public isUserLoading = false;
  public isRolesLoading = false;
  public isUserRolesLoading = false;
  public isUserSaving = false;
  /** `true` if the user's roles are invalid or inconsistent, e. g. if a role does not have any orgaentities. */
  public hasFaultyRoles = false;
  public isContactsLoading = false;

  private translInfoNotFoundTitle = '';
  private translInfoNotFoundText = '';
  private translSaveErrorTitle = '';
  private translSaveErrorText = '';

  public confirmModal?: ModalComponentOptions;

  constructor(
    private locationService: Location,
    private locationsService: LocationsService,
    private logger: NGXLogger,
    private ngxTranslate: TranslateService,
    private popupService: PopupService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translationService: TranslationService,
    private userService: UserService,
    private usersService: UsersService,
  ) {
    const countriesCodes = Object.entries(getCountries());
    this.selectCountries = [
      {
        value: '-',
        label: '⚠ No country',
      },
      ...countriesCodes.map((c) => ({
        value: c[0],
        label: c[1],
      })),
    ];

    const allLanguages = Object.entries(getAllLanguages());
    this.selectLanguages = [
      {
        value: '-',
        label: '⚠ No language',
      },
      ...allLanguages.map((c) => ({
        value: c[0],
        label: c[1],
      })),
    ];

    this.selectStatus = Object.values(UserStatus).map((status) => ({
      value: status,
      label: this.getStatusLabel(status),
    }));

    this.loadRoles();
    this.loadOrgaentities();
    this.loadTranslations();
  }

  public async ngOnInit(): Promise<void> {
    this.resetModel();
    this.resetModal();

    this.routeParamsSub$ = this.route.params.subscribe((params) => {
      this.userId = Number(params.userId);
      this.logger.debug('Setting user ID', this.userId);

      this.resetModel();

      this.loadUser();
    });

    this.canEdit = await this.userService.hasRight(RoleRight.USER_UPDATE);
  }

  public ngOnDestroy(): void {
    if (this.routeParamsSub$) {
      this.routeParamsSub$.unsubscribe();
    }
  }

  /**
   * Get status label by status name.
   *
   * Examples:
   * - `UserStatus.Active` -> `"✅ Active"`
   * - `UserStatus.PasswordResetRequired` -> `"⚠️ Password reset required"`
   *
   * @param status - User status
   * @returns User status label
   */
  private getStatusLabel(status: UserStatus): string {
    // "PASSWORD_RESET_REQUIRED" -> "Password reset required"
    const statusLabel = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase().replace(/_/g, ' ');

    switch (status) {
      case UserStatus.Active:
        return `✅ ${statusLabel}`;
      case UserStatus.Pending:
        return `⌛ ${statusLabel}`;
      case UserStatus.PendingAlice:
        return `⌛ ${statusLabel}`;
      case UserStatus.PasswordResetRequired:
        return `⚠️ ${statusLabel}`;
      case UserStatus.Disabled:
        return `❌ ${statusLabel}`;
      default:
        return statusLabel;
    }
  }

  private loadTranslations(): void {
    this.translationService.get([
      'ADMIN_PAGES.INFO_NOTFOUND_TITLE',
      'ADMIN_PAGES.INFO_NOTFOUND_TEXT',
      'ADMIN_PAGES.USERS.DETAILS.SAVE_ERROR_TITLE',
      'ADMIN_PAGES.USERS.DETAILS.SAVE_ERROR',
    ]).subscribe((texts: { [key: string]: string }) => {
      this.translInfoNotFoundTitle = texts['ADMIN_PAGES.INFO_NOTFOUND_TITLE'];
      this.translInfoNotFoundText = texts['ADMIN_PAGES.INFO_NOTFOUND_TEXT'];
      this.translSaveErrorTitle = texts['ADMIN_PAGES.USERS.DETAILS.SAVE_ERROR_TITLE'];
      this.translSaveErrorText = texts['ADMIN_PAGES.USERS.DETAILS.SAVE_ERROR'];
    });
  }

  private async loadUser(): Promise<void> {
    this.isUserLoading = true;

    if (this.userId && this.userId !== -1) {
      try {
        const user = await this.usersService.getByIdLegacy(this.userId);
        if (!user) {
          throw new Error('Could not fetch user');
        }
        this.model = new UserModel(
          user.id,
          user.sub,
          user.username,
          user.firstname,
          user.lastname,
          user.email,
          user.phone,
          user.jobtitle,
          !user.country ? '-' : user.country.toUpperCase(),
          !user.language ? '-' : user.language.toLowerCase(),
          '',
          user.status,
          user.lastlogin,
          user.creationDate,
          [],
        );
        this.contacts = user.contacts;
        this.isSsoUser = this.model.username.startsWith('DaimlerSSO_');
        this.mercedesBenzId = this.isSsoUser ? this.model.username.replace('DaimlerSSO_', '') : undefined;

        this.isUserRolesLoading = true;
        const usersRoles = await this.usersService.getUsersRoles(this.model.id);
        this.model.roles = usersRoles.items.map((ur) => ({
          id: ur.id,
          roleId: ur.role.id,
          orgaentitiesIds: ur.orgaentities.map((oes) => oes.id),
        }));
        this.hasFaultyRoles = this.model.roles.some((role) => role.orgaentitiesIds.length === 0);
        this.isUserRolesLoading = false;
      } catch (e: any) {
        this.logger.error(`User ${String(this.userId)} could not be loaded`, e);

        const errMsg = e.message ?? (e.errors && e.errors.length ? e.errors[0].message : 'Unknown');

        this.showErrorModal(
          this.translInfoNotFoundTitle,
          this.translInfoNotFoundText + '<br /><br /><small>' + errMsg + '</small>',
          true,
        );
      } finally {
        this.isUserLoading = false;
      }
    }
  }

  private async loadOrgaentities(): Promise<void> {
    this.isOrgaentitiesLoading = true;

    const oes = await this.locationsService.getOrgaentities();
    this.selectOrgaentities = oes.map((oe) => ({
      value: oe.id,
      label: oe.name + (oe.gssn ? ' | GSSN: ' + oe.gssn : ''),
    }));

    this.isOrgaentitiesLoading = false;
  }

  private async loadRoles(): Promise<void> {
    this.isRolesLoading = true;

    const roles = await this.usersService.getRoles();
    this.selectRoles = roles.map((r) => ({
      value: r.id,
      label: r.name,
    }));

    this.isRolesLoading = false;
  }

  private resetModel(): void {
    this.model = new UserModel(-1, '', '', '', '', '', '', '', '-', '-', '', UserStatus.Pending, null, null, []);
  }

  public cancel(): void {
    this.locationService.back();
  }

  public async save(): Promise<void> {
    this.isUserSaving = true;

    this.logger.debug('Saving User', this.model);

    try {
      await this.usersService.updateUser(this.model);

      this.toastr.success(
        String(this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.SAVE_SUCCESS', { username: this.model.username })),
        String(this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.SAVE_SUCCESS_TITLE')),
      );

      this.resetModel();
      this.loadUser();
    } catch (e: any) {
      const errMsg = e.message ?? (e.errors && e.errors.length ? e.errors[0].message : 'Unknown');

      this.logger.error('Could not save user', this.model, e);

      this.confirmModal = {
        visible: true,
        title: this.translSaveErrorTitle,
        content: this.translSaveErrorText + '<br /><br /><small>' + errMsg + '</small>',
        dismiss: this.resetModal.bind(this),
        dismissButtonText: undefined,
        confirm: undefined,
        confirmButtonText: '',
      };
    } finally {
      this.isUserSaving = false;
    }
  }

  public deleteUser(): void {
    this.logger.debug('Delete user called, showing confirmation popup');
    // Show confirmation popup to delete user.
    this.popupService.open(
      PopupGenericConfirmationComponent,
      {
        size: PopupSize.SMALL,
        customValues: {
          title: this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.CONFIRM_DELETE_TITLE', { username: this.model.username }),
          message: this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.CONFIRM_DELETE'),
          okButtonText: this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.DELETE'),
          okButtonClass: 'btn-danger',
        },
      },
      {
        okButton: async () => {
          this.logger.debug('Confirmed user delete');
          try {
            this.userDeleteInProgress = true;
            await this.usersService.deleteUser(this.model);
            this.userDeleteInProgress = false;
            this.logger.debug('User deleted', this.model);

            this.toastr.success(
              String(this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.DELETE_SUCCESS_MESSAGE', { username: this.model.username })),
              String(this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.DELETE_SUCCESS_TITLE')),
            );

            this.resetModal();
            this.router.navigate(['/users']);
          } catch (e: any) {
            this.userDeleteInProgress = false;
            this.logger.error('Deleting user failed', e);
            this.popupService.open(
              PopupGenericMessageComponent,
              {
                size: PopupSize.SMALL,
                customValues: {
                  title: this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.DELETE_ERROR_TITLE'),
                  message: this.ngxTranslate.instant('ADMIN_PAGES.USERS.DETAILS.DELETE_ERROR_MESSAGE')
                    + '<br /><b>'
                    + (e.message ?? (e.errors && e.errors.length ? e.errors[0].message : 'Unknown error occurred'))
                    + '</b>',
                },
              },
            );
          }
        },
      },
    );
  }

  public onRoleSelect(newRoleId: number, userRolesIndex: number): void {
    this.model.roles[userRolesIndex].roleId = newRoleId;
    this.logger.debug('User roles changed', JSON.stringify(this.model.roles));
  }

  public onOrgaentitySelect(newOEId: number, userRolesIndex: number, orgaentitiesIndex: number): void {
    this.model.roles[userRolesIndex].orgaentitiesIds[orgaentitiesIndex] = newOEId;
    this.logger.debug('User roles changed', JSON.stringify(this.model.roles));
  }

  public onOrgaentityRemove(userRolesIndex: number, orgaentitiesIndex: number): void {
    this.model.roles[userRolesIndex].orgaentitiesIds.splice(orgaentitiesIndex, 1);
    if (this.model.roles[userRolesIndex].orgaentitiesIds.length === 0) {
      this.model.roles.splice(userRolesIndex, 1);
    }
    this.logger.debug('User roles changed', JSON.stringify(this.model.roles));
  }

  public addOrgaentityToRole(userRolesIndex: number): void {
    if (this.model.roles[userRolesIndex].orgaentitiesIds[this.model.roles[userRolesIndex].orgaentitiesIds.length - 1] !== null) {
      this.model.roles[userRolesIndex].orgaentitiesIds.push(null);
    }
  }

  public addRole(): void {
    this.model.roles.push({
      id: -1,
      roleId: 2,
      orgaentitiesIds: [null],
    });
  }

  private showErrorModal(title: string, content = '', goBack = false): void {
    this.confirmModal = {
      dialogType: 'error',
      title,
      content,
      visible: true,
      dismiss: () => {
        this.resetModal();
        if (goBack) {
          this.cancel();
        }
      },
      dismissButtonText: undefined,
      confirm: undefined,
      confirmButtonText: undefined,
    };
  }

  private showConfirmModal(title: string, content: string, onConfirm?: (() => void) | undefined) {
    this.confirmModal = {
      title,
      content,
      visible: true,
      dismiss: () => {
        this.resetModal();
      },
      dismissButtonText: 'Cancel',
      confirm: onConfirm ? onConfirm.bind(this) : undefined,
      confirmButtonText: 'Delete',
    };
  }

  private resetModal() {
    this.logger.debug('Modal reset');

    this.confirmModal = {
      visible: false,
      title: '',
      content: '',
      dismiss: () => {},
      dismissButtonText: undefined,
      confirm: undefined,
      confirmButtonText: '',
    };
  }

  public getContactText(c: IContact): string {
    return c.name + ', ' + c.street + ', ' + c.zip + ' ' + c.city + ', ' + c.country;
  }

  public async resetPassword(): Promise<void> {
    this.isUserSaving = true;

    try {
      await this.usersService.resetPassword(this.model.id);
      this.showConfirmModal('Password Reset', 'Password has been reset and mail has been sent.');
    } catch (error: any) {
      const errorMsg = error.message ?? (error.errors && error.errors.length > 0 ? error.errors[0].message : '');

      this.showConfirmModal('Password Reset Failed', String(errorMsg));
    } finally {
      this.isUserSaving = false;
    }
  }

  public async activateUser(): Promise<void> {
    this.isUserSaving = true;

    try {
      await this.usersService.activateUser(this.model.id);
      this.showConfirmModal('User Activated', 'User has been activated, password has been generated, and mail has been sent.');

      this.loadUser();
    } catch (error: any) {
      const errorMsg = error.message ?? (error.errors && error.errors.length > 0 ? error.errors[0].message : '');

      this.showConfirmModal('User Activation Failed', String(errorMsg));
    } finally {
      this.isUserSaving = false;
    }
  }
}
