import { Pipe, PipeTransform } from '@angular/core';
import { OrgaentityType } from '@dep/common/core-api/types/orgaentity.type';
import { type IOrgaentityTreeItem } from '@dep/common/interfaces';

@Pipe({
  name: 'rowIsNode',
})
export class RowIsNodePipe implements PipeTransform {
  public transform(row: IOrgaentityTreeItem): boolean {
    return [
      OrgaentityType.None,
      OrgaentityType.Company,
      OrgaentityType.Location,
    ].indexOf((row.item.type as OrgaentityType | undefined) ?? OrgaentityType.None) !== -1;
  }
}
