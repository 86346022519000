<div class="popup-form">
  <div class="row no-gutters mb-4">
    <h3 class="mr-4">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_DEALER_INFORMATION' | translate }}</h3>
    <span class="pt-2">
      <img class="mr-2" src="assets/icons/location.svg" alt="location">
      <span style="color: #9F9F9F;">{{ popup?.settings?.customValues?.name }}</span>
    </span>
  </div>
  <form #contactForm="ngForm">
    <div *ngIf="isLoading" class="loading-form-popup">
      <div class="loading"></div>
    </div>
    <div>
      <h5>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.TECHNICAL' | translate }}</h5>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.NAME' | translate }}*</label>
          <p [class.form-row-error]="name.invalid && name.dirty && name.touched">
            <input
              name="name"
              type="text"
              #name="ngModel"
              [(ngModel)]="contacts[0].name"
              class="form-control form-control-sm"
              [disabled]="isFormDisabled"
              placeholder="Name"
              required
            />
          </p>
        </div>
        <div class="form-group col-md-6">
          <label>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.PHONE' | translate }}*</label>
          <p [class.form-row-error]="phone_number.invalid && phone_number.dirty && phone_number.touched">
            <input
              type="tel"
              pattern="^[0-9-+////\s()]*$"
              name="phone_number"
              #phone_number="ngModel"
              [(ngModel)]="contacts[0].phone"
              class="form-control form-control-sm"
              [disabled]="isFormDisabled"
              required
            />
          </p>
        </div>
      </div>
      <div class="form-group">
        <label>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.EMAIL' | translate }}*</label>
        <p [class.form-row-error]="e_Mail.invalid && e_Mail.dirty && e_Mail.touched">
          <input
            name="e_Mail"
            #e_Mail="ngModel"
            [(ngModel)]="contacts[0].mail"
            type="email"
            pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+$"
            placeholder="E-mail"
            class="form-control form-control-sm"
            [disabled]="isFormDisabled"
            required
          />
        </p>
      </div>
      <hr class="my-4">
      <h5>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.COMMERCIAL' | translate }}</h5>
      <div class="my-3">
        <input
          type="checkbox"
          id="sameContactsCheckedInput"
          data-md-icheck
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="isSameContactsChecked"
          style="cursor: pointer"
          class="align-text-top"
        >
        <label class="ml-2 font-weight-normal" for="sameContactsCheckedInput">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.COMMERCIAL_SAME_AS_TECHNICAL' | translate }}</label>
      </div>
      <div *ngIf="!isSameContactsChecked">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.NAME' | translate }}*</label>
            <p [class.form-row-error]="nameComm.invalid && nameComm.dirty && nameComm.touched">
              <input
                name="nameComm"
                type="text"
                #nameComm="ngModel"
                [(ngModel)]="contacts[1].name"
                class="form-control form-control-sm"
                [disabled]="isFormDisabled"
                placeholder="Name"
                required
              />
            </p>
          </div>
          <div class="form-group col-md-6">
            <label>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.PHONE' | translate }}*</label>
            <p [class.form-row-error]="phone_numberComm.invalid && phone_numberComm.dirty && phone_numberComm.touched">
              <input
                type="tel"
                pattern="^[0-9-+////\s()]*$"
                name="phone_numberComm"
                #phone_numberComm="ngModel"
                [(ngModel)]="contacts[1].phone"
                class="form-control form-control-sm"
                [disabled]="isFormDisabled"
                required
              />
            </p>
          </div>
        </div>
        <div class="form-group">
          <label>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.EMAIL' | translate }}*</label>
          <p [class.form-row-error]="e_MailComm.invalid && e_MailComm.dirty && e_MailComm.touched">
            <input
              name="e_MailComm"
              type="email"
              #e_MailComm="ngModel"
              [(ngModel)]="contacts[1].mail"
              pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+$"
              placeholder="E-mail"
              class="form-control form-control-sm"
              [disabled]="isFormDisabled"
              required
            />
          </p>
        </div>
      </div>
      <hr>

      <div class="form-group pt-4">
        <h5>{{ 'ADMIN_PAGES.CLIENTS.DETAILS.MEDIA_INTEGRATOR' | translate }}</h5>
        <div *ngFor="let mediaintegrator of mediaintegrators; let i = index; trackBy: arrayIndex" class="row mb-3">
          <div class="col-10 col-md-6">
            <input
              type="text"
              name="mediaintegrators-{{ i }}"
              #mediaIntegrator="ngModel"
              [(ngModel)]="mediaintegrators[i]"
              class="form-control"
              [disabled]="isLoading"
              pattern="[^,]*"
              [required]="i > 0 || (i === 0 && mediaintegrators.length > 1)"
              [class.form-field-error]="mediaIntegrator.invalid && mediaIntegrator.touched"
            />
          </div>
          <a *ngIf="mediaintegrators.length > 1" href="javascript: void(0)" class="img-wrapper" (click)="mediaintegrators.splice(i, 1)">
            <img src="assets/icons/delete.svg" [alt]="'ADMIN_PAGES.CLIENTS.DETAILS.REMOVE_MEDIA_INTEGRATOR' | translate" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.REMOVE_MEDIA_INTEGRATOR' | translate" />
          </a>
        </div>
        <div>
          <img src="assets/icons/plus_blue.svg" alt="+" height="14px" class="mb-1"><a href="javascript: void(0)" (click)="mediaintegrators.push('')" class="ml-1 primary">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ADD_MEDIA_INTEGRATOR' | translate }}</a>
        </div>
      </div>

      <hr class="my-5" style="margin-left: -44px; margin-right: -44px;">
      <div class="text-right mb-4">
        <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
        <button style="margin-left: 18px" type="submit" class="btn btn-primary" (click)="saveDealerInformation()" [disabled]="isFormDisabled ||!contactForm.valid">{{ 'ADMIN_PAGES.SAVE' | translate }}</button>
      </div>
    </div>
  </form>
</div>
