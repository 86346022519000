<h1>{{ 'PRODUCT_CHOOSER.' + popup?.settings?.customValues?.title | translate }}</h1>

<div class="container-fluid mt-4">
  <div class="row">
    @for (productName of productNames; track productName) {
      <div class="product-container col-12 col-md-6 pl-0">
        <div
          class="product-block"
          [class.product-block-selected]="selectedProduct === productName"
          (click)="onProductClick(productName)"
        >
          <img src="assets/licenses/{{ productName | lowercase }}_cover.jpg" class="w-100" />
          <div class="product-block-description">
            <p class="mb-0"><strong>{{ 'PRODUCT_CHOOSER.' + productName | translate }}</strong></p>
          </div>
        </div>
      </div>
    }
  </div>

  <!-- Buttons -->
  <div class="footer row">
    <div class="col-12 px-0 text-right">
      <p class="mb-0">
        <button
          type="button"
          class="btn btn-secondary mr-3"
          (click)="onPopupClose()"
        >{{ 'PRODUCT_CHOOSER.CANCEL' | translate }}</button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!selectedProduct"
          (click)="onProductSubmit()"
        >{{ 'PRODUCT_CHOOSER.SELECT' | translate }}</button>
      </p>
    </div>
  </div>
</div>
