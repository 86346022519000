import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityClient } from '@dep/common/interfaces';

@Pipe({
  name: 'asClient',
})
export class AsClientPipe implements PipeTransform {
  public transform(input: any): IOrgaentityClient {
    return input;
  }
}
