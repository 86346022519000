import { Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Pipe({
  name: 'dateDayBefore',
})
export class DateDayBeforePipe implements PipeTransform {
  constructor(
    public logger: NGXLogger,
  ) { }

  /**
   * Transforms a `Date` or `string` to an ISO8601 `string` of the day before.
   */
  public transform(input: Date | string): string {
    try {
      const d = new Date(input);
      d.setDate(d.getDate() - 1);
      return d.toISOString();
    } catch (e) {
      this.logger.error('Could not get the day before of date', input, e);
      return '';
    }
  }
}
