import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  IDeviceMetricItem,
  IOrgaentityClient,
  IOrgaentityTreeItem,
  RoleRight,
} from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';

import { getPlaylistInfo } from '@dep/frontend/model-playlist-mapping';
import { ClientIconColorPipe } from '@dep/frontend/pipes/client-icon-color.pipe';
import { UserHasRightPipe } from '@dep/frontend/pipes/user-has-right.pipe';
import { PopupRcpsContentEditComponent } from '@dep/frontend/popups/rcps-content-edit/rcps-content-edit.component';
import { DeviceSettingsService } from '@dep/frontend/services/device-settings.service';
import { OrgaentitiesService, OneMirrorLicense } from '@dep/frontend/services/orgaentities.service';
import { PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { TranslationService } from '@dep/frontend/services/translation.service';

@Component({
  selector: 'app-clients-list-insights[row]',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss'],
})
export class ClientsListInsightsComponent implements OnInit {
  @Input() public row!: IOrgaentityTreeItem;
  @Output() public rcpsContentChanged = new EventEmitter<{ orgaentityId: number, playlistName: string }>();

  public readonly enumOneMirrorLicense = OneMirrorLicense;
  public readonly LICENSE_VALIDITY_WARNING_PERIOD = 2592000; // 30 days
  public readonly RoleRight = RoleRight;

  // Metrics
  public isMetricsLoading = false;
  public metrics?: IDeviceMetricItem[];

  // Player Content
  public isContentLoading = false;

  // Licenses
  public licensesExpanded = false;

  private translations: Record<string, string> = {};

  constructor(
    public orgaentitiesService: OrgaentitiesService,
    private logger: NGXLogger,
    private translationService: TranslationService,
    private userHasRightPipe: UserHasRightPipe,
    private deviceSettingsService: DeviceSettingsService,
    private popupService: PopupService,
    private clientIconColorPipe: ClientIconColorPipe,
  ) {
    this.loadTranslations();
  }

  public ngOnInit(): void {
    this.loadClientMetrics();

    this.loadPlayerContent();
  }

  private loadTranslations(): void {
    this.translationService.get([
      'ADMIN_PAGES.CLIENTS.LIST.VERSION',
      'ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE',
      'ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED',
      'ADMIN_PAGES.CLIENTS.LIST.LAST_HEARTBEAT',
      'ADMIN_PAGES.CLIENTS.LIST.CONNECTED',
      'ADMIN_PAGES.CLIENTS.LIST.DISCONNECTED',
      'ADMIN_PAGES.CLIENTS.LIST.NO_ELIGIBLE_PLAYERS',
      'ADMIN_PAGES.CLIENTS.LIST.TO_BASKET_TEXT_SELECT_PLAYER',
      'ADMIN_PAGES.CLIENTS.LIST.ERROR_TOO_MANY_RESULTS',
      'ADMIN_PAGES.CLIENTS.LIST.OK',
      'ADMIN_PAGES.CLIENTS.LIST.CRITICAL',
      'ADMIN_PAGES.CLIENTS.LIST.WARNING',
      'ADMIN_PAGES.CLIENTS.LIST.UNKNOWN',
      'ADMIN_PAGES.CLIENTS.LIST.RUNNING',
      'ADMIN_PAGES.CLIENTS.LIST.UNSTABLE',
      'ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL',
      'ADMIN_PAGES.CLIENTS.LIST.FREE',
    ]).subscribe({
      next: (texts) => {
        this.translations = texts;
      },
    });
  }

  /**
   * Load this client's metrics.
   */
  private async loadClientMetrics(): Promise<void> {
    if (this.row.item.type !== 'CLIENT') {
      return;
    }

    this.isMetricsLoading = true;
    try {
      // Casting to client is possible because we already checked for item type "CLIENT" before.
      const { playername } = (this.row.item as IOrgaentityClient);
      const deviceMetrics = await this.orgaentitiesService.getDeviceMetrics(playername);
      this.metrics = deviceMetrics.items;
    } catch (metricsError) {
      this.logger.error('Getting device metrics failed', metricsError);
    } finally {
      this.isMetricsLoading = false;
    }
  }

  private async loadPlayerContent(): Promise<void> {
    if (
      this.isStageWallPlayer(this.row.item as IOrgaentityClient)
      && await this.userHasRightPipe.transform(RoleRight.CLIENT_CONTENT_CHANGE)
    ) {
      this.getPlayerContent(this.row.item as IOrgaentityClient);
    }
  }

  private isStageWallPlayer(item: IOrgaentityClient): boolean {
    return (item.type === 'CLIENT' && this.orgaentitiesService.getClientTypeAbbreviation(item.playername) === 'wa');
  }

  /**
   * Gets Metrics icon color. If Connection status is red, the icon color is always grey for Icinga metrics.
   *
   * @param metricName Metric name. e.g. OS, RocketStatus, etc.
   * @returns Metrics icon's color (for available colors, see `getOnDemandMetricInfo()`)
   */
  public getMetricsIconColor(metricName: string, row: IOrgaentityTreeItem): string {
    // If the player is not available, we cannot display a real red or green state for all Icinga metrics.
    // Reason: When a player is disconnected, the IP could change which would lead to false-positive statuses.
    if (!['RCPSData', 'RocketStatus', 'OneMirrorData'].includes(metricName) && this.getOnlineStatus().status === 'red') {
      return 'grey';
    }
    return this.getOnDemandMetricInfo(metricName, row).status;
  }

  /**
   * Get specific metric info, used for all client metrics except Connection (Online/Offline status) and Disk Space,
   * those two have their own methods to get live metric info
   * Icon colors can be Green(ok) | Yellow(warning) | Red(critical) | Grey(unknown)
   *
   * @param metricName DSSystem/Screenhost/Sophos/Date/OS/SpeedTest/RocketStatus/LenovoWarranty/OpenVPN
   */
  public getOnDemandMetricInfo(metricName: string, item: IOrgaentityTreeItem): IMetricInfo {
    const metricDetails = {
      status: 'grey',
      statusText: this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE'],
      lastUpdated: this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED'] + ': ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
      foundMetric: true,
      hostname: (item.item as IOrgaentityClient).playername, // initialize hostname with static data from tree
      location: this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
      devicetype: this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
      division: (item.item as IOrgaentityClient).division ?? this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
      version: this.translations['ADMIN_PAGES.CLIENTS.LIST.VERSION'] + ' ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
    };
    if (this.metrics) {
      const metric = this.metrics.find((d) => d.name === metricName);
      if (!metric) {
        metricDetails.foundMetric = false;
        return metricDetails;
      }
      // DSSystem, Screenhost, Sophos (Security Software) and VPN Connection
      if (metric.type === 'status') {
        if (metric.value.search(/ok/i) !== -1) {
          metricDetails.status = 'green';
          metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.RUNNING'];
        } else if (metric.value.search(/warning/i) !== -1) {
          metricDetails.status = 'yellow';
          metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.WARNING'];
        } else if (metric.value.search(/critical/i) !== -1) {
          metricDetails.status = 'red';
          metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.CRITICAL'];
        }
      }
      // OS, Rocket Status, Device Time and Lenovo Warranty
      if (metric.type === 'output') {
        if (metric.value.search(/ok/i) !== -1) {
          metricDetails.status = 'green';
          metricDetails.statusText = '';
          if (metricName === 'Date') {
            const splitString = (metric.value.slice(metric.value.search(/:\s/i) + 2)).split(' ');
            for (const str of splitString) {
              if (!str.match(/TimeZone:|UTC|^$/i)) {
                if (str.search(/\//gi) !== -1) {
                  metricDetails.statusText = this.translationService.formatDateTime(new Date(str)).slice(0, 10);
                } else {
                  metricDetails.statusText += ' ' + str.substring(0, str.length - 3);
                }
              }
            }
          } else if (metricName === 'LastTimeUp') {
            // Metric value example: "OK: 2022-08-23T06:25:53.865Z"
            const splitString = (metric.value.slice(metric.value.search(/:\s/i) + 2)).split(' ');
            let statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'];
            if (/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/g.test(splitString[0])) {
              statusText = this.translationService.formatDateTime(new Date(splitString[0]));
            } else if (splitString[0]) {
              [statusText] = splitString;
            }
            metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_HEARTBEAT'] + ': ' + statusText;
          } else if (metricName === 'LenovoWarranty') {
            const index = metric.value.search(/:\s/i);
            const tmp = this.translationService.formatDateTime(new Date(metric.value.slice(index + 2)));
            metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL'] + ': ' + (tmp).substring(0, tmp.length - 5);
          } else { // Operating System and Rocket Status
            const index = metric.value.search(/:\s/i);
            metricDetails.statusText = this.translationService.getTranslationMigrationStatus(metric.value.slice(index + 2));
          }
        } else if (metric.value.search(/critical/i) !== -1) {
          metricDetails.status = 'red';
          if (metric.value.search(/windows/i) !== -1 || metricName === 'RocketStatus') {
            const index = metric.value.search(/:\s/i);
            metricDetails.statusText = this.translationService.getTranslationMigrationStatus(metric.value.slice(index + 2));
          } else {
            metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.CRITICAL'];
          }
        } else if (metric.value.search(/warning/i) !== -1) {
          metricDetails.status = 'yellow';
          if (metricName === 'RocketStatus') {
            const index = metric.value.search(/:\s/i);
            metricDetails.statusText = this.translationService.getTranslationMigrationStatus(metric.value.slice(index + 2));
          } else {
            metricDetails.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.WARNING'];
          }
        }
      }
      // Parse json type metrics (RCPSData, Internet Speed).
      if (metric.type === 'json') {
        try {
          const parsedArray = JSON.parse(metric.value) as Record<string, string | number>;

          // Format RCPSData.
          if (metric.name === 'RCPSData') {
            metricDetails.hostname = String(parsedArray.hostname) || (item.item as IOrgaentityClient).playername;
            if (typeof parsedArray.location === 'string') {
              metricDetails.location = this.translationService.formatClientLocationName(parsedArray.location);
            }
            if (typeof parsedArray.devicetype === 'string') {
              metricDetails.devicetype = this.translationService.formatClientDeviceType(parsedArray.devicetype);
            }
            if (typeof parsedArray.installedPlayerVersion === 'string') {
              metricDetails.version = this.translations['ADMIN_PAGES.CLIENTS.LIST.VERSION'] + ' ' + parsedArray.installedPlayerVersion;
            }
            if (typeof parsedArray.playerStatus === 'string') {
              // Set `statusText` to "UNKNOWN", "INACTIVE", "PLAYING", or a similar player status.
              metricDetails.statusText = parsedArray.playerStatus;

              // Set the `status` to
              //   GREEN  if PLAYING
              //   YELLOW if ACTIVE/INACTIVE
              //   RED    if UNKNOWN/OFFLINE
              switch (parsedArray.playerStatus) {
                case 'PLAYING':
                  metricDetails.status = 'green';
                  break;
                case 'ACTIVE':
                case 'INACTIVE':
                  metricDetails.status = 'yellow';
                  break;
                default:
                  metricDetails.status = 'red';
                  break;
              }
            }
          } else if (metric.name === 'OneMirrorData') {
            const parsedOneMirrorData = JSON.parse(metric.value);

            if (typeof parsedOneMirrorData.version === 'string') {
              metricDetails.version = this.translations['ADMIN_PAGES.CLIENTS.LIST.VERSION'] + ' ' + parsedArray.version;
            }
            if (typeof parsedArray.installation_time === 'string') {
              metricDetails.lastUpdated = this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED'] + ': ' + this.translationService.formatDateTime(
                new Date(parsedArray.installation_time),
              );
            }
          } else {
            // Format Internet Speed, Icon color Green | Grey.
            metricDetails.status = 'green';
            // TODO: What's the type here? Needs to be checked and fixed when there is any
            for (const obj of (parsedArray as any)) {
              if (obj.name === 'download') {
                metricDetails.statusText = 'DL: ' + Math.round(Number(obj.value)) + ' Mbit/s';
              }
              if (obj.name === 'upload') {
                metricDetails.statusText += ' UL: ' + Math.round(Number(obj.value)) + ' Mbit/s';
              }
            }
          }
        } catch (e) {
          // Could not parse and process metric value.
        }
      }
      // Last Updated
      if (metric.updatedAt && metric.updatedAt !== '') {
        metricDetails.lastUpdated = this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED']
          + ': ' + this.translationService.formatDateTime(new Date(metric.updatedAt));
      }
    }
    return metricDetails;
  }

  /**
   * Connection Status determined by live client metrics 'OnlineStatus' value.
   * If 'OnlineStatus' is CRITICAL (status: red) for a player (IOrgaentityTreeItem),
   * all metrics icons for that player are displayed in grey.
   * Icon color G|Y|R
   */
  public getOnlineStatus(): { statusText: string; status: string; lastUpdated: string; } {
    const deviceStatus = {
      statusText: this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE'],
      status: 'red',
      lastUpdated: this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED'] + ': ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
    };
    if (this.metrics) {
      const metric = this.metrics.find((d) => d.name === 'OnlineStatus');
      if (!metric) {
        return deviceStatus;
      }
      if (metric.value.search(/ok/i) !== -1) {
        deviceStatus.status = 'green';
        deviceStatus.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.CONNECTED'];
      } else if (metric.value.search(/warning/i) !== -1) {
        deviceStatus.status = 'yellow';
        deviceStatus.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.UNSTABLE'];
      } else if (metric.value.search(/critical/) !== -1) {
        deviceStatus.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.DISCONNECTED'];
      }
      if (metric.updatedAt && metric.updatedAt !== '') {
        deviceStatus.lastUpdated = this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED']
          + ': ' + this.translationService.formatDateTime(new Date(metric.updatedAt));
      }
    }
    return deviceStatus;
  }

  /**
   * Check Disk Space details from live client metrics 'DiskSpace'
   * Icon color Green | Yellow | Red | Grey
   * @param metricName DiskSpace
   */
  public checkDiskSpace(metricName: string): {
    statusText: string;
    status: string;
    space: string;
    lastUpdated: string;
  } {
    const diskSpace = {
      statusText: this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN_STATE'],
      status: 'grey',
      space: '',
      lastUpdated: this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED'] + ': ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'],
    };

    if (this.metrics) {
      const metric = this.metrics.find((d) => d.name === metricName);
      if (!metric) {
        return diskSpace;
      }
      const index = metric.value.search(/.%/);
      if (index !== -1) {
        let tmpFreeSpace = 0;
        try {
          const value1 = metric.value.charAt(index - 1);
          const value2 = metric.value.charAt(index);
          tmpFreeSpace = Number((!Number.isNaN(Number(value1)) ? value1 : '') + value2);
        } catch (e) {
          // Could not parse and process metric value.
        }

        diskSpace.space = tmpFreeSpace + '% ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.FREE'];

        if (metric.value.search(/DISK OK/g) !== -1) {
          diskSpace.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.OK'] + ': ' + diskSpace.space;
          diskSpace.status = 'green';
        } else if (metric.value.search(/DISK CRITICAL/g) !== -1) {
          diskSpace.status = 'red';
          diskSpace.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.CRITICAL'] + ': ' + diskSpace.space;
        } else if (metric.value.search(/DISK WARNING/g) !== -1) {
          diskSpace.status = 'yellow';
          diskSpace.statusText = this.translations['ADMIN_PAGES.CLIENTS.LIST.WARNING'] + ': ' + diskSpace.space;
        }
      }
      // Last Updated
      if (metric.updatedAt && metric.updatedAt !== '') {
        diskSpace.lastUpdated = this.translations['ADMIN_PAGES.CLIENTS.LIST.LAST_UPDATED']
          + ': ' + this.translationService.formatDateTime(new Date(metric.updatedAt));
      }
    }
    return diskSpace;
  }

  /**
   * Counts the number of metrics as per status (Ok/Critical/Unknown).
   * Used to display metrics status under "Show more info".
   */
  public countMetricStatus(): string {
    let ok = 0;
    let critical = 0;
    let unknown = 1; // Changes this number according to the number of metrics under "Show more info".

    if (this.metrics) {
      for (const metric of this.metrics) {
        // To add more items to the "Show more" metrics, extend the following
        // line, e. g. `.match(/Sophos|Date|LenovoWarranty/i)`.
        if (metric.name.match(/Sophos/i)) {
          if (metric.value.search(/ok/i) !== -1) {
            ok++;
            unknown--;
          } else if (metric.value.search(/critical/i) !== -1) {
            critical++;
            unknown--;
          }
        }
      }
    }
    return this.translations['ADMIN_PAGES.CLIENTS.LIST.OK'] + ': '
      + ok + ' | ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.CRITICAL'] + ': '
      + critical + ' | ' + this.translations['ADMIN_PAGES.CLIENTS.LIST.UNKNOWN'] + ': '
      + unknown;
  }

  /**
   * Checks if a provided validity date has expired or has reached an 'Expires soon'
   * period defined in 'LICENSE_VALIDITY_WARNING_PERIOD'.
   *
   * @param validity Validity date string
   * @returns Translation key for validity status displayed in the license details:
   * `ADMIN_PAGES.CLIENTS.LIST.{OK, EXPIRED, EXPIRES_SOON}`. Empty string if no validity
   * date was provided.
   */
  public getValidityStatus(validity: string): string {
    if (!validity) {
      return '';
    }

    if (this.isExpired(validity)) {
      return 'ADMIN_PAGES.CLIENTS.LIST.EXPIRED';
    }

    const dateDiff = new Date(validity).getTime() - new Date().getTime();

    if (dateDiff < this.LICENSE_VALIDITY_WARNING_PERIOD) {
      return 'ADMIN_PAGES.CLIENTS.LIST.EXPIRES_SOON';
    }

    return 'ADMIN_PAGES.CLIENTS.LIST.OK';
  }

  public isExpired(validity: string): boolean {
    return new Date(validity).getTime() < new Date().getTime();
  }

  /**
   * Get player assigned content and add to the `flatTree`.
   */
  public async getPlayerContent(item: IOrgaentityClient): Promise<void> {
    this.isContentLoading = true;
    try {
      this.row.assignedContent = await this.deviceSettingsService.getPlayerContent(item.playername);
      this.logger.debug('Got player content', this.row.assignedContent);

      // A player can have multiple assigned contents.
      // Show model name for the first assigned content.
      if (this.row.assignedContent) {
        this.row.assignedContentModel = getPlaylistInfo(this.row.assignedContent[0])?.modelName;
      }
      this.isContentLoading = false;
    } catch (err) {
      this.isContentLoading = false;
      this.logger.error('Getting player content failed', err);
    }
  }

  private asClient(obj: any): IOrgaentityClient {
    return obj;
  }

  /**
   * Open popup to edit rcps content.
   *
   * @param row - Organizational entity tree row item
   */
  public openRcpsContentEditPopup(row: IOrgaentityTreeItem): void {
    this.popupService.open(
      PopupRcpsContentEditComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          orgaentityId: String(row.item.id),
          name: this.asClient(row.item).name,
          playername: this.asClient(row.item).playername,
          clientColor: this.clientIconColorPipe.transform(row.item),
          clientAbbreviation: this.orgaentitiesService.getClientTypeAbbreviation(this.asClient(row.item).playername),
        },
      },
      {
        onRcpsContentChange: (playlistName: string) => {
          this.rcpsContentChanged.emit({ orgaentityId: row.item.id, playlistName });
        },
      },
    );
  }
}

interface IMetricInfo {
  status: string;
  statusText: string;
  lastUpdated: string;
  foundMetric: boolean;
  hostname: string;
  location: string;
  devicetype: string;
  division?: string;
  version: string;
}
