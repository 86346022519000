@if (!isFocusModeActive) {
  <ul class="mb-3 d-lg-none">
    <a (click)="menuCollapsed = !menuCollapsed">{{ 'ADMIN_PAGES.MENU' | translate }} <img class="menu-open-icon" src="assets/icons/arrow_down.png" srcset="assets/icons/arrow_down@2x.png 2x"></a>
  </ul>
  <div class="d-lg-block mb-3 user-select-none" [class.d-none]="menuCollapsed">
    @if (isLoading) {
      <div class="loading loading-white loading-absolute"></div>
    }

    <ul class="mb-3 menu-list" [class.menu-list-is-loading]="isLoading">
      @for (menuItem of menu; track menuItem.name) {
        @if (!(menuItem.slug | startsWith: 'https://')) {
          <a
            [routerLink]="['/' + menuItem.slug]"
            routerLinkActive
            #routerLinkActiveReference=routerLinkActive
            [ngClass]="{'active': routerLinkActiveReference.isActive && !(menuItem.name === 'ADMIN_MENU.SHOP' && currentSlug === 'shop/orders')}"
            (click)="menuCollapsed = true; setCurrentSlug(menuItem.slug)"
            class="d-flex align-items-center"
          >
            @if (menuItem.icon) {
              <img src="assets/icons/side-navigation/{{ menuItem.icon }}" class="mr-2">
            }
            <div class="ml-1">{{ menuItem.name | translate }}</div>

            <!-- Orders indicator -->
            <span *ngIf="menuItem.slug === 'shop/orders' && isOrdersIndicatorVisible" class="tooltip-custom">
              <span class="orders-indicator ml-1"></span>
              <span class="tooltiptext">{{ 'ADMIN_PAGES.ORDERS.ORDERS_INDICATOR_TOOLTIPTEXT' | translate }}</span>
            </span>
          </a>
        }
        @else if (menuItem.slug | startsWith: 'https://') {
          <!-- URL link (external) -->
          <a
            href="{{ menuItem.slug }}"
            target="_blank"
            rel="noopener noreferrer"
            class="d-flex"
          >
            @if (menuItem.icon) {
              <img src="assets/icons/side-navigation/{{ menuItem.icon }}" class="mr-2">
            }
            <div class="ml-1">{{ menuItem.name | translate }}</div>
          </a>
        }
      }
    </ul>

    <app-help-desk></app-help-desk>
  </div>
}
