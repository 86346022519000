import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom, Subject, Subscription } from 'rxjs';

import { AppsyncService } from './appsync.service';
import { UserService } from './user.service';

import { onConfigUpdate, updateConfig } from '@dep/frontend/appsync.queries';
import { environment } from 'src/environments/environment';

/**
 * Configs service.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configs?: { key: string, value: string }[];
  private useHttpEndpoint = true;
  private onConfigUpdate$?: Subscription;
  private httpInterval?: NodeJS.Timeout;
  public configsObservable$: Subject<{ key: string, value: string }> = new Subject();

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private appSync: AppsyncService,
    private userService: UserService,
  ) {
    this.fetchHttp();

    this.userService.sessionObservable$.subscribe({
      next: (cognitoUser) => {
        this.useHttpEndpoint = !cognitoUser;

        if (this.useHttpEndpoint) {
          this.logger.debug('Configs: Using HTTP endpoint');
          this.stopSubscription();
          this.startHttpInterval();
        } else {
          this.logger.debug('Configs: Using Subscription');
          this.stopHttpInterval();
          this.startSubscription();
        }
      },
    });
  }

  /**
   * Update a config item.
   * @param key Config key
   * @param value New config value
   */
  public async update(key: string, value: string): Promise<unknown> {
    return this.appSync.query(updateConfig, { key, value });
  }

  /**
   * List the current configs content ("cache").
   */
  public list(): { key: string; value: string; }[] | undefined {
    return this.configs;
  }

  /**
   * Fetch configs via HTTP, cache the content in `configs` and trigger `configsObservable$`.
   */
  private async fetchHttp(): Promise<void> {
    return lastValueFrom(this.http.get<{ key: string, value: string }[]>(
      environment.config.apiGateway.url + '/configs',
    )).then((response) => {
      this.configs = response;
      for (const config of response) {
        this.configsObservable$.next(config);
      }
    });
  }

  /**
   * Call fetchHttp every 30 seconds.
   * Used when subscriptions cannot be used (e. g. unauthorized).
   */
  private startHttpInterval(): void {
    if (this.httpInterval === undefined) {
      this.httpInterval = setInterval(() => {
        this.fetchHttp();
      }, 30000);
    }
  }

  private stopHttpInterval(): void {
    if (this.httpInterval !== undefined) {
      clearInterval(this.httpInterval);
      this.httpInterval = undefined;
    }
  }

  /**
   * Subscribe (AppSync) to configs changes.
   * Only works when user credentials are set.
   */
  private startSubscription(): void {
    if (!this.onConfigUpdate$) {
      // TODO: Type { key: string; value: string; } instead of any does not work.
      this.onConfigUpdate$ = this.appSync.subscribe<any>(onConfigUpdate).subscribe({
        next: (data) => {
          this.logger.debug('Configs: Subscription: New value', data, data.value.data.onConfigUpdate);
          this.configsObservable$.next(data.value.data.onConfigUpdate as { key: string; value: string; });
        },
        error: (err) => {
          this.logger.error('Configs: Subscription failed', err);
          this.configsObservable$.error(err);
        },
      });
    }
  }

  private stopSubscription(): void {
    if (this.onConfigUpdate$) {
      this.onConfigUpdate$.unsubscribe();
      this.onConfigUpdate$ = undefined;
    }
  }
}
