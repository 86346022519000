import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityClient } from '@dep/common/interfaces';

import { OrgaentityDataPipe } from './orgaentity-data.pipe';

@Pipe({
  name: 'clientValidUntil',
})
export class ClientValidUntilPipe implements PipeTransform {
  constructor(
    private orgaentityDataPipe: OrgaentityDataPipe,
  ) { }

  public transform(client: IOrgaentityClient): string | undefined {
    const dataValidUntil = this.orgaentityDataPipe.transform(client.data, 'validUntil');
    if (dataValidUntil) {
      return dataValidUntil;
    }

    if (client.validUntil) {
      return client.validUntil;
    }

    return undefined;
  }
}
