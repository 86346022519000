<div
  class="clients-list content-block content-block-padding content-block-height"
>
  <h1 *ngIf="!embedded">{{ 'ADMIN_PAGES.CLIENTS.LIST.CLIENTS' | translate }}</h1>
  <form class="content-block-bar row" (submit)="$event.preventDefault(); onFiltersUpdate(searchTermInput.value);">
    <div class="col-8 col-md-5 p-0 pr-4">
      <div class="search-input-container">
        <input
          #searchTermInput
          name="searchTermInput"
          class="form-control form-control-sm"
          type="search"
          (search)="searchTermInput.value === '' ? onFiltersUpdate(searchTermInput.value) : undefined"
          placeholder="{{ 'ADMIN_PAGES.CLIENTS.LIST.SEARCH' | translate }}"
        />
        <img
          src="assets/icons/search.svg"
          class="search-input-button"
          (click)="onFiltersUpdate(searchTermInput.value);"
        />
      </div>
    </div>
    @if (RoleRight.CLIENT_READ | userHasRight | async) {
      <div class="col-md-6 renewed">
        <div class="row pt-2">
          <div class="mr-4">
            <input class="align-text-top" type="checkbox" name="hideAlreadyRenewed" [(ngModel)]="hideAlreadyRenewed" (change)="hideRenewedClients()" />
            <span class="ml-2">{{ 'ADMIN_PAGES.CLIENTS.LIST.HIDE_CLIENTS_1' | translate }}</span>
            <span class="client-secondary-badge-success font-weight-bold mx-1">{{ 'ADMIN_PAGES.CLIENTS.LIST.RENEWED' | translate }}</span>
            <span>{{ 'ADMIN_PAGES.CLIENTS.LIST.HIDE_CLIENTS_2' | translate }}</span>
          </div>
          <div>
            <input class="align-text-top" type="checkbox" name="hideEmpty" [(ngModel)]="hideEmpty" (change)="hideEmptyRows()" />
            <span class="ml-2">{{ 'ADMIN_PAGES.CLIENTS.LIST.HIDE_EMPTY' | translate }}</span>
          </div>
        </div>
      </div>
    }
  </form>
  <div class="overflow-auto pt-1">
    <div *ngIf="isLoading" class="loading loading-center mt-5"></div>
    <table class="table table-sm table-admin table-minheight mb-2" [class.isLoading]="isLoading">
      <thead>
        <tr>
          <!-- #1 Header Column -->
          <th class="border-top-0 border-bottom-0 table-columns-first" scope="col"></th>
          <!--/ #1 Header Column -->

          <!-- #2 Header Column -->
          <th class="border-top-0 border-bottom-0 text-right table-columns-second" style="width: 15%" scope="col">
            <div class="d-none d-lg-flex justify-content-center">
              <div class="mr-1">
                <img src="assets/icons/digitalsignage.svg" class="client-icon"/>
              </div>
              <div>
                <div>
                  <span class="d-none d-lg-inline-block">{{ 'ADMIN_PAGES.CLIENTS.LIST.RCPS_PLAYER' | translate }}</span>
                </div>
                <div class="d-flex justify-content-start table-header-sub-title">
                  <span class="d-none d-lg-inline-block">{{ 'ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL' | translate }}</span>
                </div>
              </div>
            </div>
          </th>
          <!--/ #2 Header Column -->

          <!-- #3 Header Column -->
          <th *ngIf="IS_ONEMIRROR_COLUMN_VISIBLE" class="border-top-0 border-bottom-0 text-right table-columns-third" style="width: 15%" scope="col">
            <div class="d-none d-lg-flex justify-content-center">
              <div class="mr-1">
                <img src="assets/icons/one_mirror_white.svg" class="client-icon"/>
              </div>
              <div>
                <div>
                  <span class="d-none d-lg-inline-block">{{ 'ADMIN_PAGES.CLIENTS.LIST.ONEMIRROR' | translate }}</span>
                </div>
                <div class="d-flex justify-content-start table-header-sub-title">
                  <span class="d-none d-lg-inline-block"> {{ 'ADMIN_PAGES.CLIENTS.LIST.VALID_UNTIL' | translate }} </span>
                </div>
              </div>
            </div>
          </th>
          <!--/ #3 Header Column -->

          <!-- #4 Header Column -->
          <th class="border-top-0 border-bottom-0 text-right" style="width: 10%" scope="col">
            <div class="d-none d-lg-flex justify-content-center">
              <div class="mr-1">
                <img src="assets/icons/key_down_white.svg" class="client-icon"/>
              </div>
              <div>
                <div>
                  <span class="d-none d-lg-inline-block">{{ 'ADMIN_PAGES.CLIENTS.LIST.LICENSES' | translate }}</span>
                </div>
                <div class="d-flex justify-content-start table-header-sub-title">
                  <span class="d-none d-lg-inline-block"> {{ 'ADMIN_PAGES.CLIENTS.LIST.RENEW' | translate }} </span>
                </div>
              </div>
            </div>
          </th>
          <!--/ #4 Header Column -->
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of flatTree; let i = index;">
          <tr
            *ngIf="row.visible && !row.disabled"
            [id]="'clientRow-' + row.item.id"
            class="row-orgaentity"
            [class.client-clickable]="row.item.type !== 'MATCHMAKER' && row.item.type !== 'HANDOVERCOMPANION' && row.item.type !== 'CARSHOWADAPTER' && (row.item.type !== 'CLIENT' || !row.expanded)"
            (click)="row.item.type !== 'CLIENT' && row.item.type !== 'CARMEDIASTICK' ? toggleRow(row, $event) : expandClient($event, row)"
          >
            <td class="table-columns-first" [style.paddingLeft]="(10 + (row.level * 25)) + 'px'" [ngClass]="{'font-weight-bold': row.expanded && row.children.length > 0}">
              <span *ngIf="(row | rowIsNode) && !row.expanded">
                <img src="assets/icons/plus.svg" class="expand-icon" [title]="'ID: ' + row.item.id" />
              </span>
              <span *ngIf="(row | rowIsNode) && row.expanded">
                <img src="assets/icons/minus.svg" class="expand-icon" style="width: 14px; height: 14px;" [title]="'ID: ' + row.item.id" />
              </span>
              <!-- Leaf Icon -->
              <span *ngIf="(row.item | clientIsEnergyManagementEnabled)" style="margin-right: 8px; margin-left: -20px;">
                <img class="align-baseline" src="assets/icons/energy_conservation.png" height="12px" width="12px"/>
              </span>
              <!-- Client Icon -->
              <span *ngIf="row.item.type === 'CLIENT'" class="tooltip-custom">
                <img
                  src="assets/icons/client_{{ (row.item | asClient).playername | clientTypeAbbreviation }}_{{ row.item | clientIconColor }}.svg"
                  class="client-icon mr-1"
                  [title]="'ID: ' + row.item.id"
                />
                <span class="tooltiptext-icon">{{ ('ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.CLIENT_ICON_TEXT_' + (row.item | clientIconColor | uppercase)) | translate }}</span>
              </span>
              <!-- Carshowadapter Icon -->
              <span *ngIf="row.item.type === 'CARSHOWADAPTER'">
                <img src="assets/icons/carshowadapter.svg" class="client-icon mr-1" [title]="'ID: ' + row.item.id" />
              </span>
              <!-- Matchmaker Icon -->
              <span *ngIf="row.item.type === 'MATCHMAKER'">
                <img src="assets/icons/matchmaker.svg" class="client-icon mr-1" [title]="'ID: ' + row.item.id" />
              </span>
              <!-- Handover Companion Icon -->
              <span *ngIf="row.item.type === 'HANDOVERCOMPANION'">
                <img src="assets/icons/handovercompanion.svg" class="client-icon mr-1" [title]="'ID: ' + row.item.id" />
              </span>
              <!-- C@Rmedia Stick Icon -->
              <span *ngIf="row.item.type === 'CARMEDIASTICK'">
                <img src="assets/icons/car_media_stick.svg" class="client-icon mr-1" [title]="'ID: ' + row.item.id"/>
              </span>
              <!-- Showroom:Connect Icon -->
              <span *ngIf="row.item.type === 'SHOWROOMCONNECT'">
                <img src="assets/icons/showroomconnect_white.svg" class="client-icon mr-1" [title]="'ID: ' + row.item.id"/>
              </span>
              <!-- IOrgaentityTreeItem Name -->
              <span *ngIf="(row | rowIsNode)" class="row-title mr-2 tooltip-custom">
                {{ row.item.name }}
                <span class="tooltiptext-icon" style="left: 30px; font-weight: normal;">
                  {{ 'GS' + (row.item.gssn ? row.item.gssn : '—') }}
                  <ng-container *ngIf="row.item.brandCode">
                    {{ ' | ' + row.item.brandCode }}
                  </ng-container>
                </span>
              </span>
              <!--
                Warning icon if billing address has not been added and the warning was not explicitly hidden (e. g. by the market).
                Displaying the icon has a small delay, because the required ancestors are loaded in a non-blocking way, and we do not want to show the icon and then hide it (icon flashing up).
              -->
              @if (
                (row | rowIsNode)
                && (RoleRight.ORGAENTITY_UPDATE_BILLING | userHasRight: row.item | async)
                && !(row.item.data | orgaentityData: 'hasBillingAddress')
                && ancestors !== undefined
                && (row | billingAddressWarningIsShown: flatTree: ancestors)
              ) {
                <span class="tooltip-custom mr-2">
                  <img src="assets/icons/warning_yellow.svg" alt="Warning" class="mb-1">
                  <span class="tooltiptext-icon" style="left: -4px; font-weight: normal;">
                    {{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.TOOLTIP' | translate }}
                  </span>
                </span>
              }
              <!-- Retailer/Player Name -->
              <span *ngIf="!(row | rowIsNode)" [class.font-weight-bold]="row.expanded && row.item.type === 'CLIENT'" class="no-underline mr-3">
                {{ row.item.name }}
              </span>
              <a
                *ngIf="userRoles && (row.item | rowHasContextMenu: userRoles: supportForms)"
                (click)="$event.stopPropagation(); contextMenuVisibilityIndex = contextMenuVisibilityIndex === i ? undefined : i;"
                class="no-underline contextmenu-icon"
              >
                <img src="assets/icons/contextmenu.svg" alt="Context menu" />
              </a>
              <!-- Context Menu -->
              <div
                *ngIf="contextMenuVisibilityIndex === i"
                class="contextmenu-overlay"
                onclick="event.stopPropagation()"
              >
                <!-- Restart Icon -->
                <a *ngIf="(row.item.type === 'CLIENT') && (row.item | clientIsIOTAssigned) && (RoleRight.CLIENT_IOT_REBOOT | userHasRight: row.item | async)" (click)="openPlayerRestartPopup(row)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.SYSTEM_RESTART' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/restart_blue.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SYSTEM_RESTART' | translate }}</span></a>

                <!-- Show Edit Dealer Information Overlay Icon -->
                <a *ngIf="row.item.type === 'LOCATION' && (RoleRight.ORGAENTITY_UPDATE_DEALER | userHasRight: row.item | async)" (click)="openEditDealerInformationPopup(row)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_DEALER_INFORMATION' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/edit_dealer_information.svg" srcset="assets/icons/edit_dealer_information.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_DEALER_INFORMATION' | translate }}</span></a>

                <!-- Add C@Rshow Adapter Icon -->
                <a *ngIf="(RoleRight.CARSHOWADAPTER_CREATE | userHasRight: row.item | async) && row.item.type === 'LOCATION'" href="javascript: void(0);" (click)="openCarshowAdapterCreatePopup(row)" [title]="'ADMIN_PAGES.CLIENTS.LIST.CREATE_CARSHOWADAPTER' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/carshowadapter_add_blue.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.LIST.CREATE_CARSHOWADAPTER' | translate }}</span></a>

                <!-- Edit OE Icon -->
                <a *ngIf="(RoleRight.ORGAENTITY_UPDATE | userHasRight: row.item | async) && row.item.type !== 'HANDOVERCOMPANION'" (click)="openOrgaentityEditPopup(row, row.item.id)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_CLIENT' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/edit.svg" srcset="assets/icons/edit.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EDIT_CLIENT' | translate }}</span></a>

                <!-- Assign licenses icon -->
                <a *ngIf="(RoleRight.LMS_LICENSES_ASSIGN | userHasRight: row.item | async) && row.item.type === 'CLIENT'" [routerLink]="['/assign-licenses', (row.item | asClient).playername]" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.ASSIGN_LICENSES' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/license_blue.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ASSIGN_LICENSES' | translate }}</span></a>

                <!-- support-ticket-create Icon -->
                <a *ngIf="(row.item | clientHasSupportTicketForm: supportForms) && (RoleRight.SUPPORTTICKET_READ | userHasRight: row.item | async)" (click)="customRouterLink(row)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.CREATE_TICKET' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/ticket_blue.svg" srcset="assets/icons/ticket_blue.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CREATE_TICKET' | translate }}</span></a>

                <!-- OE Create Icon -->
                <a *ngIf="(RoleRight.ORGAENTITY_CREATE | userHasRight: row.item | async) && (row | rowIsNode)" (click)="openOrgaentityCreatePopup(row)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.ADD_ORGAENTITY' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/node_add_blue.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ADD_ORGAENTITY' | translate }}</span></a>

                <!-- Clients Export Icon -->
                <a *ngIf="(RoleRight.ORGAENTITY_READ | userHasRight: row.item | async) && (row | rowIsNode)" href="javascript: void(0);" (click)="downloadCSVExport(row.item.id)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.EXPORT_CLIENTS' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/download.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.EXPORT_CLIENTS' | translate }}</span></a>

                <!-- Auto Alert Subscription Icon -->
                <a *ngIf="row.item.type === 'LOCATION'" (click)="showSetupSubscriptionModal(row)" title="{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.TITLE' | translate }}" class="no-underline ml-1 tooltip-custom"><img src="assets\icons\auto_alert_subscription_blue_new.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.SUBSCRIPTION_MODAL.TITLE' | translate }}</span></a>

                <!-- RCPS Settings Icon -->
                <a *ngIf="(row.item.type === 'CLIENT') && (row.item | clientIsRCPSPlayer) && (RoleRight.CLIENT_IOT | userHasRight: row.item | async)" (click)="openRcpsSettingsPopup(row)" title="{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.TITLE' | translate }}" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/settings_blue.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.TITLE' | translate }}</span></a>

                <!-- Billing Address Icon -->
                <a *ngIf="(row | rowIsNode) && (RoleRight.ORGAENTITY_UPDATE_BILLING | userHasRight: row.item | async)" (click)="openBillingAddressUpdatePopup(row)" [title]="'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.EDIT' | translate" class="no-underline ml-1 tooltip-custom"><img src="assets/icons/billing_address_circle.svg"><span class="tooltiptext-menu">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.BILLING_ADDRESS.EDIT' | translate }}</span></a>

                <!-- Settings/Energy Management Icon -->
                <!-- Visible if: a) it is a CLIENT which is IOT assigned and the user has the required rights OR b) it is a LOCATION and the user has the required rights -->
                <a
                  *ngIf="((row.item.type === 'CLIENT') && (row.item | clientIsIOTAssigned: true) && (RoleRight.CLIENT_IOT_ENERGY | userHasRight: row.item | async)) || (row.item.type === 'LOCATION' && (RoleRight.CLIENT_IOT_ENERGY | userHasRight: row.item | async))"
                  (click)="openEnergyManagementPopup(row)"
                  title="{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.TITLE' | translate }}"
                  class="no-underline ml-1 tooltip-custom"
                >
                  <img src="assets/icons/energy_circle.png">
                  <span class="tooltiptext-menu">
                    {{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.TITLE' | translate }}
                  </span>
                </a>
              </div>
            </td>

            <!-- Valid Until: Digital Signage -->
            <td
              class="text-center no-break p-0"
              [class.table-columns-second]="row.level === 0 || row.item.type !== 'CLIENT' || !row.expanded"
            >
              <div
                class="d-flex justify-content-center p-2"
                [class.table-columns-second]="row.level !== 0 && row.item.type === 'CLIENT' && row.expanded"
                [style.margin-left]="row.level !== 0 && row.item.type === 'CLIENT' && row.expanded ? '-1px' : 'unset'"
              >
                <span class="d-none d-lg-inline-block" *ngIf="(row.item | asClient | clientValidUntil) as validUntil">
                  <ng-container
                    *ngIf="
                      (row.item.type === 'CLIENT' && (row.item | asClient).orgaentitySubtypeId === 1)
                      && (row.item | asClient).type !== ClientType.CarshowAdapter
                    "
                  >
                    <span class="tooltip-custom">
                      <span>{{ validUntil | dateDayBefore | date: 'dd.MM.yyyy' }}</span>
                      <!-- Currently disabled. Is used once a year to inform the users about the upcoming renewal. -->
                      <!-- <span *ngIf="row.item.type === 'CLIENT'" class="tooltiptext-date-legend" [innerHTML]="'ADMIN_PAGES.CLIENTS.LIST.TOOLTIP_TEXTS.VALID_UNTIL_LEGEND_TEXT' | translate"></span> -->
                    </span>
                  </ng-container>
                  <span *ngIf="(row.item.type === 'CLIENT' && (row.item | asClient).orgaentitySubtypeId !== 1)">-</span>
                </span>
              </div>
            </td>

            <!-- Valid Until: OneMirror -->
            <td
              *ngIf="IS_ONEMIRROR_COLUMN_VISIBLE"
              class="text-center no-break p-0"
              [class.table-columns-third]="row.level === 0 || row.item.type !== 'CLIENT' || !row.expanded"
            >
              <div
                *ngIf="row.item | asClient | clientHasLicense: enumOneMirrorLicense.NAME"
                class="d-none d-lg-flex justify-content-center p-2"
                [class.table-columns-third]="!(row.level === 0 || row.item.type !== 'CLIENT' || !row.expanded)"
              >
                <img *ngIf="isExpired(orgaentitiesService.getOneMirrorLicense(row.item | asClient).validity)" src="assets/icons/warning.svg" class="license-warn-icon mt-1 mr-2" />
                <ng-container *ngIf="(row.item | asClient | clientHasLicense: enumOneMirrorLicense.LICENSE_BASIC) && !(row.item | asClient | clientHasLicense: enumOneMirrorLicense.LICENSE_PRO)">
                  <div class="license-type license-type-basic"><span>{{ 'ADMIN_PAGES.CLIENTS.LIST.BASIC' | translate }}</span></div>
                </ng-container>
                <ng-container *ngIf="row.item | asClient | clientHasLicense: enumOneMirrorLicense.LICENSE_PRO">
                  <div class="license-type license-type-pro"><span>{{ 'ADMIN_PAGES.CLIENTS.LIST.PRO' | translate }}</span></div>
                </ng-container>
              </div>
            </td>
            <td *ngIf="row | rowIsNode" class="text-center" colspan="2">
              <!-- <div *ngIf="isChildVisible(row)" class="checkbox-below-hint-left"></div> -->
              <input
                type="checkbox"
                [(ngModel)]="row.selected"
                (change)="onRowSelected(row)"
                [disabled]="(ShopRoleRight.ShopRead | userHasRight: row.item | async) !== true"
                class="align-middle"
              />
              <!-- <div *ngIf="isChildVisible(row)" class="checkbox-below-hint-right"></div> -->
            </td>
            <td *ngIf="!(row | rowIsNode)" class="text-center">
              <input
                *ngIf="row.item | clientIsRenewable"
                type="checkbox"
                [(ngModel)]="row.selected"
                (change)="onRowSelected(row)"
                [disabled]="(ShopRoleRight.ShopRead | userHasRight: row.item | async) !== true"
                class="align-middle"
              />
              <span
                *ngIf="
                  (row.item | asClient).type !== ClientType.CarshowAdapter
                  && (row.item | asClient).type !== ClientType.Matchmaker
                  && (row.item | asClient).type !== ClientType.HandoverCompanion
                  && (row.item | asClient).type !== ClientType.CarMediaStick
                  && row.selected === null
                  && (row.item.type === 'CLIENT' && (row.item | asClient).orgaentitySubtypeId === 1)
                "
                class="badge badge-success client-secondary-badge-success"
              >{{ 'ADMIN_PAGES.CLIENTS.LIST.RENEWED' | translate }} <img src="assets/icons/check.svg" class="badge-success-check" /></span>
              <!-- Show a "—" for all clients which cannot be renewed (= which do not have DS or OM licenses). -->
              <span *ngIf="!(row.item | clientIsRenewable)">—</span>
            </td>
          </tr>

          <!-- Insights / Metrics container -->
          <tr *ngIf="row.visible && !row.disabled && row.expanded && (row.item.type === 'CLIENT' || row.item.type === 'CARMEDIASTICK')">
            <td colspan="4" class="pt-0" style="background: #373737; border-top: none;" [style.paddingLeft]="(10 + (row.level * 25)) + 'px'">
              <app-clients-list-insights
                [row]="row"
                (rcpsContentChanged)="onRcpsContentChanged($event)"
              ></app-clients-list-insights>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="(flatTreeLength() === 0 && !isLoading) || error">
          <tr class="row-insights">
            <td colspan="5">
              <p *ngIf="!error" class="text-center mt-3">{{ 'ADMIN_PAGES.CLIENTS.LIST.NO_CLIENTS' | translate }}</p>
              <p *ngIf="error" class="text-center mt-3 mb-2">{{ 'ADMIN_PAGES.LOADING_FAILED' | translate }}</p>
              <p *ngIf="error" class="text-center table-error-text">{{ error }}</p>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5"></td>
        </tr>
      </tfoot>
    </table>
  </div>

  <span *ngIf="RoleRight.CARSHOWADAPTER_CREATE | userHasRight | async" class="carshow-hint"><img src="assets/icons/info.svg" class="info-icon" /> {{ 'ADMIN_PAGES.CLIENTS.LIST.CARSHOWADAPTER_HINT_1' | translate }} <img src="assets/icons/carshowadapter.svg" style="vertical-align: -2px;"> {{ 'ADMIN_PAGES.CLIENTS.LIST.CARSHOWADAPTER_HINT_2' | translate }}</span>
  <span class="license-renewal-button mb-3" [class.license-renewal-button-sticky]="isAnyPlayerSelected">
    <div
      *ngIf="isLoadingRenew"
      style="display: inline-block; vertical-align: -7px;"
      class="mr-2"
    >
      <div class="loading"></div>
    </div>
    <button
      *ngIf="ShopRoleRight.ShopRead | userHasRight | async"
      type="button"
      class="btn btn-primary font-weight-normal"
      [disabled]="isLoadingRenew"
      (click)="selectLicenseTypeForBasket()"
    >
      <img src="assets/icons/basket.png" srcset="assets/icons/basket@2x.png 2x" class="add-to-basket" /> {{ 'ADMIN_PAGES.CLIENTS.LIST.RENEW_LICENSES' | translate }}
    </button>
  </span>
</div>

<app-info-modal
  [visible]="showModal"
  title="{{ modalTitle | translate }}"
  content="{{ modalContent | translate }}"
  [dismiss]="onModalDismissFn.bind(this)"
></app-info-modal>
<app-autoalert-subscription-modal
  [visible]="showSubscriptionModal"
  [oeId]="subscriptionModalOeId"
  [subscriptionModalLocationName]="subscriptionModalLocationName"
  [dismiss]="onModalDismissFn.bind(this)"
></app-autoalert-subscription-modal>
