<div [ngClass]="isCurrentRouteLogin || isFocusModeActive ? 'container-login' : 'container-fluid'">
  <app-header></app-header>

  @if (isAuthGuardPending) {
    <div class="text-center">
      <img class="rounded mx-auto d-block img-responsive" src="assets/loading_spinner.gif" alt="{{ 'LOGIN.LOADING_PERMISSIONS' | translate }}" />
      <br>
      {{ 'LOGIN.LOADING_PERMISSIONS' | translate }}
    </div>
  }

  <div [class.d-none]="isAuthGuardPending"> <!-- Do not show any content (e. g. login) while auth guard is pending. -->
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>

@for (popup of popups$ | async; track popup.uuid) {
  <app-popup-base
    [popup]="popup"
  >
    <!-- Popup content is inserted dynamically -->
  </app-popup-base>
}
