import { Injectable } from '@angular/core';
import { INGXLoggerConfig, INGXLoggerMetadata, NGXLoggerWriterService } from 'ngx-logger';

/**
 * Service for ngx-logger that allows the user to jump from the console log into the source code.
 *
 * @see https://github.com/dbfannin/ngx-logger/issues/289#issuecomment-1079695379
 */
@Injectable({
  providedIn: 'root',
})
export class LoggerWriterService extends NGXLoggerWriterService {
  protected prepareMetaString(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): string {
    // TODO: Can we get the source code link working with the new build system (Angular 17, esbuild)?
    // const fileName = metadata.fileName?.startsWith('src/')
    //   ? 'webpack://' + metadata.fileName
    //   : metadata.fileName;
    const fileName = './frontend/src' + (metadata.fileName?.substring(3) ?? '');
    return super.prepareMetaString({ ...metadata, fileName }, config);
  }
}
