<div class="login-component form-group">
  <h1 class="text-center" style="font-size: 40px;">{{ 'LOGIN.TITLE' | translate }}</h1>
  <p *ngIf="!isProcessing" class="text-center title-message">{{ 'LOGIN.SSO_TITLE_MESSAGE' | translate }}</p>
  <p *ngIf="!isProcessing" class="text-center">
    <button
      type="button"
      class="btn btn-primary login-btn"
      (click)="loginWithMercedesBenz()"
    >{{ 'LOGIN.LOGIN' | translate }}</button>
  </p>
  <p *ngIf="isProcessing" class="text-center" style="padding-bottom: 14px">
    <br>
    <img class="rounded mx-auto d-block img-responsive" src="assets/loading_spinner.gif" alt="{{ 'LOGIN.LOADING' | translate }}" />
    <br>
    {{ 'LOGIN.LOADING' | translate }}
  </p>
  <p *ngIf="loginError" class="text-center form-field-error-message">
    <img src="assets/icons/warning.svg" width="22" height="22" /> {{ loginError | translate }}
  </p>
  <p *ngIf="!isProcessing" class="text-center">
    <a class="help" href="mailto:dep@newroom-media.de">{{ 'LOGIN.LOGIN_HELP' | translate }}</a>
  </p>
</div>
