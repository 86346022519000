import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll } from '@dep/common/interfaces';

@Pipe({
  name: 'clientIsEnergyManagementEnabled',
})
export class ClientIsEnergyManagementEnabled implements PipeTransform {
  /**
   * Checks if the player has energy settings enabled.
   */
  public transform(client: IOrgaentityAll): boolean {
    if (!client.data) {
      return false;
    }
    const isEnergyManagementEnabled = client.data.some((data) => data.k.includes('DEA_ENERGY') && data.v.includes('ENABLED'));
    return isEnergyManagementEnabled;
  }
}
