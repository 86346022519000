import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { lastValueFrom } from 'rxjs';

import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrgaentitySubtypesService {
  private orgaentitySubtypesCache?: IModelOrgaentitySubtype[];

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private userService: UserService,
  ) { }

  public async getSubtypes(): Promise<IModelOrgaentitySubtype[]> {
    if (this.orgaentitySubtypesCache !== undefined) {
      return this.orgaentitySubtypesCache;
    }

    const accessToken = await this.userService.getAccessToken();
    if (!accessToken) {
      return Promise.reject(new Error('Unauthorized'));
    }

    this.orgaentitySubtypesCache = await lastValueFrom(this.http.get<IModelOrgaentitySubtype[]>(
      environment.config.coreApiGateway.url + '/orgaentity-subtypes',
      {
        headers: await this.userService.getAuthorizationHeaders(),
      },
    ));
    this.logger.debug('OrgaentitiesSubtypesService: Fetched orgaentity subtypes', this.orgaentitySubtypesCache);
    return this.orgaentitySubtypesCache;
  }
}

/**
 * Interface of the orgaentity subtype instance.
 */
export interface IModelOrgaentitySubtype {
  id: number;
  /** Orgaentity type to which this subtype belongs */
  orgaentityType: string;
  /** Subtype name */
  name: string;
}
