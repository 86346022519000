import {
  ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild,
} from '@angular/core';
import { IReportingMarketReports, IReportingMarketReportsItem, IReportingReportConfig } from '@dep/common/interfaces';
import { NgOption } from '@ng-select/ng-select';
import { NGXLogger } from 'ngx-logger';
import * as pbi from 'powerbi-client';

import { getMarketReports, getReport } from '@dep/frontend/appsync.queries';
import { AppsyncService } from '@dep/frontend/services/appsync.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('dashboardView') private dashboardView?: ElementRef;
  @ViewChild('dashboardContainer') private dashboardContainer?: ElementRef;
  @ViewChild('reportContainer') private reportContainer?: ElementRef;
  public config?: IReportingReportConfig;
  public isLoading = false;
  public isFailed = false;
  public isFullScreen = false;
  private powerbi?: pbi.service.Service;
  public selectedMarket?: string;
  private selectedMarketGSSN?: string;
  public userMarkets: NgOption[] = [];
  public selectedReport?: string;
  public userReports: NgOption[] = [];

  /** List of all markets with their specific reports that user can access */
  public marketReports: IReportingMarketReportsItem[] = [];

  constructor(
    private appsyncService: AppsyncService,
    private logger: NGXLogger,
    private changeDetector: ChangeDetectorRef,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.isFailed = false;

    this.powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
    );

    try {
      // get available markets and reports that user can access
      const list = await this.appsyncService.query<IReportingMarketReports>(getMarketReports, {});
      if (list) {
        this.marketReports = list.items;
      }
      // populate ng-select Market box data
      for (const market of this.marketReports) {
        this.userMarkets = [...this.userMarkets, { value: market.name, label: market.name }];
      }
    } catch (err) {
      this.isFailed = true;
      this.isLoading = false;
      this.logger.error('Could not fetch user\'s Market and reports Info', err);
    }
    // first market and report in the list is selected
    this.selectedMarket = this.marketReports[0].name;
    [this.selectedReport] = this.marketReports[0].reports;

    this.onMarketSelectionChange(this.marketReports[0].name);
  }

  /**
   * Finds all reports for a market based on user's choice. Sets values in ng-select drodown for report selection
   * @param event selected Market name
   */
  public async onMarketSelectionChange(event: string): Promise<void> {
    this.logger.debug('Market changed', event);
    // find available reports for the specific Market
    const index = this.marketReports.findIndex((market) => market.name === event);
    const availableMarketReports = this.marketReports[index].reports;
    this.selectedMarketGSSN = this.marketReports[index].gssn;

    // cache last userReports to handle cases when different market has exact same report(s)
    const userReportsCache = this.userReports.map((r) => r.value);
    // clear old values from the dropdown list
    this.userReports = [];

    // change ng-select dropdown values as per new Market selection
    for (const report of availableMarketReports) {
      this.userReports = [...this.userReports, { value: report, label: report }];
    }

    // manually trigger report change for markets with same reports
    if (userReportsCache.length === availableMarketReports.length && userReportsCache.every((value, i) => value === availableMarketReports[i])) {
      this.logger.debug('Market has same report(s). Reloading report manually with changed market');
      this.onReportSelectionChange(this.selectedReport || availableMarketReports[0]);
    } else {
      // select first report
      [this.selectedReport] = availableMarketReports;
    }
    await this.onReportSelectionChange(String(this.selectedReport));
  }

  /**
   * Gets power bi report and changes the Power BI dashboard based on user's report choice.
   * @param event selected report name
   */
  public async onReportSelectionChange(event: string): Promise<void> {
    this.logger.debug('onReportSelectionChange called with event:', event);
    // handle ng-select triggering with wrong event when market is changed
    if (this.userReports.find((r) => r.value === event)) {
      this.selectedReport = event;
    }
    this.logger.debug('Report changed', this.selectedReport);
    this.isLoading = true;
    this.isFailed = false;
    try {
      // get the specific report for the selected Market
      const apiReturnsConfig = await this.appsyncService.query<IReportingReportConfig>(
        getReport,
        {
          market: this.selectedMarket,
          report: this.selectedReport,
          gssn: this.selectedMarketGSSN,
        },
      );
      this.config = {
        ...apiReturnsConfig,
        slicers: apiReturnsConfig.slicers ? JSON.parse(apiReturnsConfig.slicers) : undefined,
        settings: apiReturnsConfig.settings ? JSON.parse(apiReturnsConfig.settings) : undefined,
      };
      this.changeDetector.detectChanges();
      this.embedReport();
    } catch (err) {
      this.logger.error('Could not load embedded Power BI dashboard', err);
      this.isFailed = true;
    } finally {
      // finish loading only when report is embeded
      this.isLoading = false;
    }
  }

  /** Toggle Full Screen */
  public toggleFullScreen(): void {
    if (this.dashboardView?.nativeElement.requestFullscreen && !this.isFullScreen) {
      this.dashboardView.nativeElement.requestFullscreen();
    } else if (document.exitFullscreen && this.isFullScreen) {
      document.exitFullscreen();
    }
  }

  /** Support for different browsers */
  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  public toggleFullScreenStatus(): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  private embedReport(): void {
    if (this.powerbi && this.config) {
      if (this.config.type === 'dashboard' && this.dashboardContainer) {
        this.powerbi.reset(this.dashboardContainer.nativeElement as HTMLElement);
        this.powerbi.embed(this.dashboardContainer.nativeElement as HTMLElement, { ...this.config } as pbi.IEmbedConfiguration);
      } else if (this.config.type === 'report' && this.reportContainer) {
        this.powerbi.reset(this.reportContainer.nativeElement as HTMLElement);
        this.powerbi.embed(this.reportContainer.nativeElement as HTMLElement, { ...this.config } as pbi.IEmbedConfiguration);
      }
    }
  }
}
