<div class="connectedshowroom content-block content-block-padding pb-0">
  <h1 class="mb-3">{{'ADMIN_PAGES.CONNECTEDSHOWROOM.TITLE' | translate }}</h1>

  <div class="card-deck" [class.card-deck-loading]="isLoading">
    <div *ngIf="isLoading" class="loading loading-form-overlay"></div>

    <ng-container *ngFor="let tool of smartRetailHubTools">
      <!-- External tools (href) -->
      <a
        *ngIf="!tool.isRouterLink"
        class="card"
        [class.disabled]="!tool.url"
        [attr.href]="tool.url ? tool.url : undefined"
        [attr.target]="tool.url ? '_blank' : undefined"
        [title]="'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.title | translate"
      >
        <div class="mx-auto card-img-group">
          <svg viewBox="0 0 100 100" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="currentColor" />
          </svg>
          <img class="img-icon" src="assets/icons/connectedshowroom/{{ tool.icon }}" [alt]="'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.title | translate" />
        </div>
        <div class="card-text my-auto px-2 text-center">
          <span>{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.title | translate }}</span>
          <span *ngIf="tool.subtitle"><br/>({{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.subtitle | translate }})</span>
        </div>
        <span *ngIf="!tool.url" class="tooltiptext">{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.TOOLTIPTEXT_SOON_AVAILABLE' | translate }}</span>
      </a>

      <!-- Internal tools (routerLink) -->
      <a
        *ngIf="tool.isRouterLink"
        class="card"
        [routerLink]="[tool.url]"
        [title]="'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.title | translate"
      >
        <div class="mx-auto card-img-group">
          <svg viewBox="0 0 100 100" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="currentColor" />
          </svg>
          <img class="img-icon" src="assets/icons/connectedshowroom/{{ tool.icon }}" [alt]="'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.title | translate" />
        </div>
        <div class="card-text my-auto px-2 text-center">
          <span>{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.title | translate }}</span>
          <span *ngIf="tool.subtitle"><br/>({{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.' + tool.subtitle | translate }})</span>
        </div>
      </a>
    </ng-container>
  </div>
</div>
