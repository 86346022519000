import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { RolesManagerPopupComponent } from '@dep/frontend/popups/roles-manager/roles-manager.component';
import { PopupService, PopupSize } from '@dep/frontend/services/popup.service';
import { UserService } from '@dep/frontend/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isLoggedIn = false;
  public username?: string;
  private sessionSub$?: Subscription;
  private routerSub$?: Subscription;
  /** `true` if anything in the header is currently being processed that should block further UI interactions */
  private isProcessing = false;

  constructor(
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly userService: UserService,
    private readonly popupService: PopupService,
  ) { }

  public ngOnInit(): void {
    // If session changes/is updated, re-init.
    this.sessionSub$ = this.userService.sessionObservable$.subscribe((cognitoUser) => {
      this.init(cognitoUser);
    });
  }

  public ngOnDestroy(): void {
    this.sessionSub$?.unsubscribe();
    this.routerSub$?.unsubscribe();
  }

  private init(cognitoUser: CognitoUser | null): void {
    this.isLoggedIn = !!cognitoUser;

    this.username = this.userService.getCurrentUsername() ?? undefined;
    this.username = this.username?.replace('DaimlerSSO_', '');
  }

  public async openRolesManager(): Promise<void> {
    if (this.isProcessing) {
      this.logger.warn('Header is still processing, cannot open roles manager');
      return;
    }

    this.isProcessing = true;

    const currentUser = await this.userService.getCurrentUser();
    if (!currentUser) {
      this.logger.error('Cannot open roles manager because user not set');
      this.isProcessing = false;
      return;
    }

    this.popupService.open(
      RolesManagerPopupComponent,
      {
        size: PopupSize.LARGE,
        customValues: {
          userFirstname: currentUser.firstname,
          userStates: JSON.stringify(currentUser.states),
        },
        closeable: true,
      },
      {
        okButton: () => {
          this.userService.clearUserCache();
        },
      },
    );

    this.isProcessing = false;
  }

  public logout(): void {
    this.userService.logout();
    this.router.navigate(['login']);
  }
}
