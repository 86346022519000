import { Pipe, PipeTransform } from '@angular/core';
import { Orgaentity } from '@dep/common/core-api/types/orgaentity.type';
import { IOrgaentityTreeItem } from '@dep/common/interfaces';

/**
 * Type guard to determine if `treeItemOrOrgaentity` is a `IOrgaentityTreeItem` or `Orgaentity`.
 *
 * @param treeItemOrOrgaentity - Input object
 * @returns Type predicate
 */
function isTreeItem(treeItemOrOrgaentity: IOrgaentityTreeItem | Orgaentity): treeItemOrOrgaentity is IOrgaentityTreeItem {
  return 'item' in treeItemOrOrgaentity && 'level' in treeItemOrOrgaentity;
}

/**
 * Recursive function that checks if the billing address is hidden (`ignoreBillingAddressWarnings`) by any parent/ancestor orgaentity.
 *
 * Needs all the tree items and the related ancestors to be able to go all the way up the root node.
 * Calls itself until there is no parent node found anymore.
 *
 * @param orgaentity - Current orgaentity to check ← accepts both tree item (from tree) and orgaentity (e. g. from `ancestors`)
 * @param flatTree - All tree orgaentities
 * @param ancestors - Ancestor orgaentities
 * @returns `true` if the billing address is hidden
 */
function isBillingAddressWarningHiddenFromAncestors(
  orgaentity: IOrgaentityTreeItem | Orgaentity,
  flatTree: IOrgaentityTreeItem[],
  ancestors: Orgaentity[],
): boolean {
  // Check `ignoreBillingAddressWarnings` property in tree item or orgaentity object.
  // If it is set, it can return `true`. This will also end the recursive calls once a
  // node with this property is found.
  if (isTreeItem(orgaentity) && orgaentity.item.data?.find((d) => d.k === 'ignoreBillingAddressWarnings')?.v === 'true') {
    return true;
  }
  if (!isTreeItem(orgaentity) && orgaentity.data?.ignoreBillingAddressWarnings) {
    return true;
  }

  // Get the parent orgaentity ID from `orgaentity` (tree item or orgaentity object).
  let parentId: number | undefined;
  if (isTreeItem(orgaentity) && orgaentity.item.parentOrgaentityId) {
    parentId = orgaentity.item.parentOrgaentityId;
  }
  if (!isTreeItem(orgaentity) && orgaentity.parentOrgaentitiesId) {
    parentId = orgaentity.parentOrgaentitiesId;
  }

  // If there is a parent node, continue with that node (recursive call with the parent node object).
  if (parentId !== undefined) {
    // Check ancestors first, because it is the shorter list.
    const ancestorParent = ancestors.find((treeItem) => treeItem.id === parentId);
    if (ancestorParent) {
      return isBillingAddressWarningHiddenFromAncestors(ancestorParent, flatTree, ancestors);
    }

    const flatTreeParent = flatTree.find((treeItem) => treeItem.item.id === parentId);
    if (flatTreeParent) {
      return isBillingAddressWarningHiddenFromAncestors(flatTreeParent, flatTree, ancestors);
    }
  }

  // If the current node does not have the property and there is no more parent node (reached root node),
  // the billing address warning must not be hidden (`false`).
  // This will also end the recursive calls by passing `false` down the stack.
  return false;
}

@Pipe({
  name: 'billingAddressWarningIsShown',
})
export class BillingAddressWarningIsShownPipe implements PipeTransform {
  /**
   * Check if array includes id.
   *
   * @param orgaentity - The node to check for its billing address visibility
   * @param flatTree - All orgaentities in the cockpit
   * @param ancestors - All ancestors of the top-level `flatTree` nodes
   */
  public transform(orgaentity: IOrgaentityTreeItem, flatTree: IOrgaentityTreeItem[], ancestors: Orgaentity[]): boolean {
    return !isBillingAddressWarningHiddenFromAncestors(orgaentity, flatTree, ancestors);
  }
}
