import { Component, OnInit } from '@angular/core';
import { OrgaentityType } from '@dep/common/core-api/types/orgaentity.type';
import {
  IOrgaentityLocationContact,
  ContactType,
  IOrgaentityLocation,
} from '@dep/common/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-edit-dealer-information',
  templateUrl: './edit-dealer-information.component.html',
  styleUrls: ['./edit-dealer-information.component.scss'],
})

export class PopupEditDealerInformationComponent implements OnInit {
  public popup?: IPopup;
  public isLoading = false;
  /** Checkbox state, indicating whether technical and commercial contact data is identical. */
  public isSameContactsChecked = true;
  public isFormDisabled = false;

  public contacts: IOrgaentityLocationContact[] = this.getEmptyContacts();
  public mediaintegrators: string[] = ['']; // Initialize with one empty item/input.

  constructor(
    private logger: NGXLogger,
    private orgaentitiesService: OrgaentitiesService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.loadContactsData();
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  /**
   * Provides an array of empty location contact objects. Always contains two items (for "TECHNICAL" and "COMMERCIAL" contacts).
   * @returns
   */
  private getEmptyContacts(): IOrgaentityLocationContact[] {
    return [
      {
        name: '',
        mail: '',
        phone: '',
        contactType: ContactType.TECHNICAL,
      },
      {
        name: '',
        mail: '',
        phone: '',
        contactType: ContactType.COMMERCIAL,
      },
    ];
  }

  public async loadContactsData(): Promise<void> {
    this.contacts = this.getEmptyContacts();
    this.isLoading = true;

    try {
      const oeLocation = await this.orgaentitiesService.getOrgaentity(Number(this.popup?.settings.customValues?.orgaentityId));
      const { mediaintegrator } = (oeLocation as IOrgaentityLocation);
      // Split comma-separated media integrators to display them in separate inputs.
      if (mediaintegrator) {
        this.mediaintegrators = mediaintegrator.split(',');
      }

      let { contacts } = oeLocation as IOrgaentityLocation;
      if (!Array.isArray(contacts)) {
        contacts = [];
      }
      if (!contacts.find((c) => c.contactType === ContactType.COMMERCIAL)) {
        contacts.push({
          contactType: ContactType.COMMERCIAL,
          name: '',
          mail: '',
          phone: '+49 ',
        });
      }
      if (!contacts.find((c) => c.contactType === ContactType.TECHNICAL)) {
        contacts.push({
          contactType: ContactType.TECHNICAL,
          name: '',
          mail: '',
          phone: '+49 ',
        });
      }

      for (const contact of contacts) {
        if (contact.contactType === ContactType.TECHNICAL) {
          this.contacts[0] = contact;
        } else if (contact.contactType === ContactType.COMMERCIAL) {
          this.contacts[1] = contact;
        }
      }

      this.isLoading = false;
      this.isSameContactsChecked = (
        this.contacts[0].name === this.contacts[1].name
        && this.contacts[0].mail === this.contacts[1].mail
        && this.contacts[0].phone === this.contacts[1].phone
      );
    } catch (err) {
      this.logger.error('Something failed while loading or parsing the contact data', err);
    }
  }

  public async saveDealerInformation(): Promise<void> {
    this.isLoading = true;

    if (this.isSameContactsChecked) {
      this.contacts[1].name = this.contacts[0].name;
      this.contacts[1].mail = this.contacts[0].mail;
      this.contacts[1].phone = this.contacts[0].phone;
    }

    this.logger.debug('Updating Orgaentity contacts', this.popup?.settings.customValues?.orgaentityId, this.contacts);
    try {
      await this.orgaentitiesService.updateOrgaentity({
        id: Number(this.popup?.settings.customValues?.orgaentityId),
        type: OrgaentityType.Location,
        contacts: this.contacts,
        mediaintegrator: this.mediaintegrators.map((mediaintegrator) => mediaintegrator.trim()).join(','),
      });
      this.isLoading = false;

      this.closePopup();
      this.toastr.success(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.CHANGE_SUCCESS',
        )),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.CHANGE_SUCCESS_TITLE')),
      );
    } catch (err) {
      this.isLoading = false;
      this.toastr.error(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.SAVE_CONTACT_ERROR',
        )),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.CONTACT.SAVE_CONTACT_ERROR_TITLE')),
      );
      this.logger.error('Saving contact failed', err);
    }
  }

  /**
   * Tracks form changes and syncs the recent changes in UI.
   * Find more: https://stackoverflow.com/questions/47843856/angular-what-is-the-point-of-implementing-trackby
   */
  public arrayIndex(i: number): number {
    return i;
  }
}
