import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { GraphQLResult } from '@aws-amplify/api/lib-esm/types';
import {
  BaseSuccess,
  IOrgaentitySubscriptionCreateInput,
  IOrgaentitySubscriptionList,
} from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';

import {
  createOrgaentitySubscription,
  getOrgaentitySubscriptions,
  removeOrgaentitySubscription,
  updateOrgaentitySubscription,
} from '@dep/frontend/appsync.queries';
import { AppsyncService } from '@dep/frontend/services/appsync.service';
import { UserService } from '@dep/frontend/services/user.service';
import { UsersService } from '@dep/frontend/services/users.service';

@Component({
  selector: 'app-autoalert-subscription-modal',
  templateUrl: './autoalert-subscription-modal.component.html',
  styleUrls: ['./autoalert-subscription-modal.component.scss'],
})
export class AutoalertSubscriptionModalComponent implements OnChanges {
  @Input() public oeId?: number;
  @Input() public subscriptionModalLocationName?: string;
  @Input() public visible = false;
  @Input() public dismiss?: (() => void);
  public isLoading = false;
  public isError = false;
  public modalErrorMessage = '';
  /** List of products available for Auto-alert email Subscription */
  public productsList: IAutoalertSubscriptionProduct[] = [
    {
      productType: 'CLIENT',
      emailSendingDays: [],
      isSubscribed: false,
    },
  ];

  private userId = -1;

  constructor(
    private appsyncService: AppsyncService,
    private logger: NGXLogger,
    private userService: UserService,
    private usersService: UsersService,
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      this.resetModal();
      if (changes.visible.currentValue === true && changes.visible.previousValue !== undefined) {
        document.getElementsByTagName('html')[0].className += ' no-scroll';
        this.logger.debug(`AutoalertSubscriptionModal: Getting subscription details for orgaentity with id: ${JSON.stringify(this.oeId)}`);
        this.fetchSubcriptionDetails(Number(this.oeId));
      } else if (changes.visible.currentValue === false) {
        document.getElementsByTagName('html')[0].className = document.getElementsByTagName('html')[0].className.replace(' no-scroll', '');
      }
    }
  }

  private resetModal(): void {
    this.isError = false;
    this.modalErrorMessage = '';
    this.productsList = [
      {
        productType: 'CLIENT',
        emailSendingDays: [],
        isSubscribed: false,
      },
    ];
  }

  public dismissModal(): void {
    // Only dismiss modal if click was not inside the modal's content div.
    if (this.dismiss && typeof this.dismiss === 'function') {
      this.dismiss();
    } else {
      this.logger.error('AutoalertSubscriptionModal: Modal could not be dismissed because of missing dismiss function');
    }
  }

  private async fetchSubcriptionDetails(oeId: number): Promise<void> {
    this.isLoading = true;
    if (this.userId === -1) {
      const user = await this.usersService.getByUsername(this.userService.getCurrentUsername() || '');
      if (user && user.id) {
        this.userId = user.id;
      } else {
        this.logger.error('AutoalertSubscriptionModal: Could not fetch user ID');
      }
    }
    try {
      // get subscriptions
      const list = await this.appsyncService.query<IOrgaentitySubscriptionList>(getOrgaentitySubscriptions, { oeId, userId: this.userId });

      if (list.items.length > 0) {
        for (const item of list.items) {
          this.productsList.forEach((p) => {
            if (item.productType === p.productType) {
              p.isSubscribed = true;
              p.emailSendingDays = item.emailSendingDays;
            }
          });
        }
      } else {
        this.logger.debug(`AutoalertSubscriptionModal: No subscription found for orgaentity: ${String(this.oeId)}`);
        this.resetModal();
      }
      this.isLoading = false;
    } catch (err: any) {
      this.logger.error('AutoalertSubscriptionModal: Getting orgaentity subscription failed', err);
      this.isLoading = false;
      this.isError = true;
      this.setModalErrorMessage(err as Error | GraphQLResult<void>, 'Getting orgaentity subscription failed.');
    }
  }

  public async createSubscription(product: IAutoalertSubscriptionProduct): Promise<void> {
    this.logger.debug('AutoalertSubscriptionModal: Creating orgaentity subcription');
    this.isLoading = true;
    const input: IOrgaentitySubscriptionCreateInput = {
      oeId: Number(this.oeId),
      userId: this.userId,
      productType: product.productType,
      emailSendingDays: product.emailSendingDays,
    };
    try {
      // create subscription
      const response = await this.appsyncService.mutate<BaseSuccess>(createOrgaentitySubscription, { input });

      if (response.success) {
        this.logger.debug('AutoalertSubscriptionModal: Subscription created');
        this.fetchSubcriptionDetails(Number(this.oeId));
      } else {
        this.logger.debug(`AutoalertSubscriptionModal: Subscription creation failed: ${String(this.oeId)}`);
        this.isLoading = false;
        this.isError = true;
        this.modalErrorMessage = 'Subscription creation failed.';
      }
    } catch (err: any) {
      this.logger.error('AutoalertSubscriptionModal: Creating orgaentity subscription failed', err);
      this.isLoading = false;
      this.isError = true;
      this.setModalErrorMessage(err as Error | GraphQLResult<void>, 'Creating orgaentity subscription failed.');
    }
  }

  public async updateSubscription(product: IAutoalertSubscriptionProduct): Promise<void> {
    this.logger.debug('AutoalertSubscriptionModal: Updating orgaentity subscription');
    this.isLoading = true;
    const input: IOrgaentitySubscriptionCreateInput = {
      oeId: Number(this.oeId),
      userId: this.userId,
      productType: product.productType,
      emailSendingDays: product.emailSendingDays,
    };
    try {
      // update subscription
      const response = await this.appsyncService.mutate<BaseSuccess>(updateOrgaentitySubscription, { input });

      if (response.success) {
        this.logger.debug('AutoalertSubscriptionModal: Subscription updated');
        this.fetchSubcriptionDetails(Number(this.oeId));
      } else {
        this.logger.debug(`AutoalertSubscriptionModal: Subscription update failed: ${String(this.oeId)}.`);
        this.isLoading = false;
        this.isError = true;
        this.modalErrorMessage = 'Subscription update failed';
      }
    } catch (err: any) {
      this.logger.error('AutoalertSubscriptionModal: Orgaentity subscription update failed', err);
      this.isLoading = false;
      this.isError = true;
      this.setModalErrorMessage(err as Error | GraphQLResult<void>, 'Orgaentity subscription update failed.');
    }
  }

  public async removeSubscription(product: IAutoalertSubscriptionProduct): Promise<void> {
    this.logger.debug('AutoalertSubscriptionModal: Removing orgaentity subscription');
    this.isLoading = true;
    const input = {
      oeId: Number(this.oeId),
      userId: this.userId,
      productType: product.productType,
    };
    try {
      // remove subscription
      const response = await this.appsyncService.mutate<BaseSuccess>(removeOrgaentitySubscription, input);

      if (response.success) {
        this.logger.debug(`AutoalertSubscriptionModal: Removed orgaentity: ${JSON.stringify(input)}`);
        this.fetchSubcriptionDetails(Number(this.oeId));
      } else {
        this.logger.debug('AutoalertSubscriptionModal: Subscription remove failed');
        this.isLoading = false;
        this.isError = true;
        this.modalErrorMessage = 'Subscription remove failed.';
      }
    } catch (err: any) {
      this.logger.error('AutoalertSubscriptionModal: Orgaentity subscription remove failed', err);
      this.isLoading = false;
      this.isError = true;
      this.setModalErrorMessage(err as Error | GraphQLResult<void>, 'Orgaentity subscription remove failed.');
    }
  }

  /**
   * Adds/removes email sending days on checking/unchecking check box input
   * @param index productsList index
   * @param event check box event
   */
  public onWeekDayChange(index: number, event: Event): void {
    if ((event.target as HTMLInputElement).checked) {
      this.productsList[index].emailSendingDays.push((event.target as HTMLInputElement).value as ('MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU'));
    } else {
      this.productsList[index].emailSendingDays = this.productsList[index].emailSendingDays.filter(((day) => day !== (event.target as HTMLInputElement).value));
    }
  }

  /**
   * Syncs check box visual with email sending days of products
   * @param index productsList index
   * @param value e.g. MO, TU... etc.
   * @returns undefined | value
   */
  public isChecked(index: number, value: string): boolean {
    return this.productsList[index].emailSendingDays.some((day) => day === value);
  }

  /**
   * Set the modal's error message from an `Error` object or the thrown `GraphQLResult` object.
   *
   * @param error - Error object that has been thrown
   * @param message - Error message that will be set as a fallback
   */
  public setModalErrorMessage(error: Error | GraphQLResult<void>, message: string): void {
    if ((error as Error).message) {
      this.modalErrorMessage = (error as Error).message;
    } else if ((error as GraphQLResult<void>).errors?.length) {
      this.modalErrorMessage = (error as GraphQLResult<void>).errors?.[0].message ?? message;
    } else {
      this.modalErrorMessage = message;
    }
  }
}

interface IAutoalertSubscriptionProduct {
  productType: string;
  emailSendingDays: ('MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU')[];
  isSubscribed: boolean;
}
