<form
  *ngIf="!setPasswordMode && !resetPasswordMode"
  class="login-component"
  (ngSubmit)="login()"
>
  <h1 class="text-center" style="font-size: 40px;">{{ 'LOGIN.TITLE' | translate }}</h1>
  <p *ngIf="!formDisabled" class="text-center title-message" style="margin-bottom: 33px;">{{ 'LOGIN.DEP_CREDENTIALS_TITLE_MESSAGE' | translate }}</p>
  <div *ngIf="!formDisabled" class="m-auto px-3" style="max-width: 530px;">
    <p>
      <input
        name="username"
        type="text"
        #usernameInput="ngModel"
        [(ngModel)]="username"
        placeholder="{{ 'LOGIN.USERNAME' | translate }}"
        autocomplete="username"
        class="form-control"
        [class.form-field-error]="(usernameInput.invalid && usernameInput.dirty && usernameInput.touched) || loginError.length"
        [disabled]="formDisabled"
        required
        autofocus
      />
    </p>
    <p>
      <input
        name="password"
        type="password"
        #passwordInput="ngModel"
        [(ngModel)]="password"
        placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
        autocomplete="current-password"
        minlength="8"
        maxlength="{{ maxPasswordLength + 1 }}"
        class="form-control"
        [class.form-field-error]="(passwordInput.invalid && passwordInput.dirty && passwordInput.touched) || loginError.length"
        [disabled]="formDisabled"
        required
      />
      <span class="text-danger form-field-error-message" *ngIf="loginError.length">{{ this.loginError | translate }}</span>
    </p>
    <div *ngIf="password.length > maxPasswordLength" class="alert alert-danger">
      {{ 'LOGIN.MAX_PASSWORD_LENGTH' | translate }}
    </div>
    <div class="row no-gutters justify-content-between pt-2">
      <a class="help" href="mailto:dep@newroom-media.de">{{ 'LOGIN.LOGIN_HELP' | translate }}</a>
      <p>
        <button type="submit" class="btn btn-primary login-btn">{{ 'LOGIN.LOGIN' | translate }}</button>
      </p>
    </div>
  </div>
  <p class="text-center" *ngIf="formDisabled" style="padding-bottom: 14px">
    <br>
    <img class="rounded mx-auto d-block img-responsive" src="assets/loading_spinner.gif" alt="{{ 'LOGIN.LOADING' | translate }}" />
    <br>
    {{ 'LOGIN.LOADING' | translate }}
  </p>
</form>

<form *ngIf="setPasswordMode" class="login-component" (ngSubmit)="setPassword()">
  <div class="m-auto px-3 text-center" style="max-width: 530px;">
    <h3 class="mb-4">{{ 'LOGIN.SET_NEW_PASSWORD' | translate }}</h3>
    <p>
      <input
        name="password"
        type="password"
        #newPasswordInput="ngModel"
        [(ngModel)]="password"
        (ngModelChange)="onChangeValidatePassword($event)"
        placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
        class="form-control"
        [class.form-field-error]="newPasswordInput.invalid && newPasswordInput.dirty && newPasswordInput.touched"
        [disabled]="formDisabled"
        required
      />
    </p>
    <p>
      <input
        name="password2"
        type="password"
        #newPassword2Input="ngModel"
        [(ngModel)]="password2"
        (ngModelChange)="onChangeValidatePassword($event, true)"
        placeholder="{{ 'LOGIN.PASSWORD_REPEAT' | translate }}"
        class="form-control"
        [class.form-field-error]="newPassword2Input.invalid && newPassword2Input.dirty && newPassword2Input.touched"
        [disabled]="formDisabled"
        required
      />
    </p>
    <div class="password-strength-meter" *ngIf="false && password.length && !newPasswordErrors.length">
      {{ 'LOGIN.PASSWORD_SET.STRENGTH_METER.LABEL' | translate }}
      <div class="bar">
        <div class="indicator-overlay" [style.width.%]="100-passwordStrengthScore"></div>
      </div>
    </div>
    <div *ngIf="password.length > maxPasswordLength" class="alert alert-danger">
      {{ 'LOGIN.MAX_PASSWORD_LENGTH' | translate }}
    </div>
    <div *ngIf="!newPasswordMatch" class="alert alert-danger" role="alert">
      {{ 'LOGIN.NEW_PASSWORDS_DONT_MATCH' | translate }}
    </div>
    <div *ngIf="newPasswordApiError.length" class="alert alert-danger" role="alert">
      {{ newPasswordApiError }}
    </div>
    <div *ngFor="let err of newPasswordErrors" class="alert alert-danger" role="alert">
      {{ 'LOGIN.PASSWORD_SET.ERROR.' + err | translate:{ var: PASSWORD_STRENGTH_CRITERIA[err].min } }}
    </div>
    <p class="text-center">
      <button type="submit" class="btn btn-primary login-btn" [disabled]="formDisabled || newPasswordInput.invalid || newPassword2Input.invalid || password.length > maxPasswordLength || !newPasswordMatch || newPasswordErrors.length">{{ 'LOGIN.CONFIRM_AND_LOGIN' | translate }}</button>
    </p>
  </div>
</form>

<form *ngIf="resetPasswordMode" class="login-component" (ngSubmit)="resetPassword()">
  <div class="m-auto px-3 text-center" style="max-width: 530px;" tabindex="-1" role="dialog" aria-labelledby="resetPasswordModal" aria-hidden="true">
    <h3 class="mb-4">{{ 'LOGIN.RESET_PASSWORD' | translate }}</h3>
    <p>
      {{ 'LOGIN.PASSWORD_RESET_DIALOG' | translate }}
    </p>
    <div class="text-center pt-2" *ngIf="!resetPasswordSent">
      <button type="submit" class="btn btn-primary login-btn" [disabled]="resetPasswordLoading">{{ 'LOGIN.RESET_PASSWORD' | translate }}</button>
    </div>
    <div *ngIf="resetPasswordSent" class="row">
      <div class="col-12 text-center">
        <p class="text-success">{{ 'LOGIN.RESET_PASSWORD_SENT' | translate }}</p>
      </div>
      <div class="col-12 text-center">
        <p><button type="button" class="btn btn-primary login-btn" (click)="resetPasswordSent = false; resetPasswordMode = false;">{{ 'LOGIN.LOGIN' | translate }}</button></p>
      </div>
    </div>
  </div>
</form>
