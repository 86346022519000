import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { HubCapsule } from '@aws-amplify/core';
import { ICredentials } from 'aws-amplify/lib/Common/types/types';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';

import { AmplifyHubService } from '@dep/frontend/services/amplify-hub.service';
import { UserService } from '@dep/frontend/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  /** `true` if login is in progress. */
  public isProcessing = false;
  public loginError?: string;
  private activatedRoute$?: Subscription;
  private sessionSub$?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private logger: NGXLogger,
    private ngZone: NgZone,
    private router: Router,
    private amplifyHubService: AmplifyHubService,
    private userService: UserService,
  ) {
    this.userService.isSignInProcessRunning.subscribe((isRunning) => {
      this.isProcessing = isRunning;
    });
  }

  public async ngOnInit(): Promise<void> {
    const cognitoUser = this.userService.getCurrentCognitoUser();
    if (cognitoUser) {
      this.onSessionChange(cognitoUser);
    }

    this.sessionSub$ = this.userService.sessionObservable$.subscribe({
      next: this.onSessionChange.bind(this),
    });

    this.activatedRoute$ = this.activatedRoute.queryParams.subscribe({
      next: (params: { [key: string]: string }) => {
        this.logger.debug('Login query parameters changed', params);

        if (params.code && params.state) {
          // SSO redirects to URL `/login?code=...&state=...`. During this SSO step, the login
          // is still in progress --> show loading indicator.
          this.isProcessing = true;
        }

        if (params.login_error) {
          this.loginError = `LOGIN.ERROR.${params.login_error}`;
          // If there was a login_error, the user can be safely signed out
          this.userService.logout();
        }

        if (params.redirectTo) {
          const { pathname } = new URL(params.redirectTo);
          localStorage.setItem('redirectTo', pathname);
        }
      },
    });

    // Check if there is/was an error in the Amplify auth events.
    if (this.amplifyHubService.authEvents$.value) {
      this.handleAuthEvent(this.amplifyHubService.authEvents$.value);
    }
  }

  public ngOnDestroy(): void {
    this.activatedRoute$?.unsubscribe();
    this.sessionSub$?.unsubscribe();
    localStorage.removeItem('redirectTo');
  }

  /**
   * Handle an "auth" event from Amplify.
   * Checks the payload of the event and sets the `loginError` accordingly.
   */
  private handleAuthEvent(authEventValue: HubCapsule): void {
    if (authEventValue.payload.event.endsWith('_failure')) {
      const payloadData = authEventValue.payload.data;
      if (typeof payloadData?.message === 'string' && payloadData.message.indexOf('DisabledUserException') >= 0) {
        this.loginError = 'LOGIN.ERROR.USER_DISABLED';
      } else if (typeof payloadData?.message === 'string' && payloadData.message.indexOf('UserMissingRolesException') >= 0) {
        this.loginError = 'LOGIN.ERROR.NO_ROLES';
      } else if (typeof payloadData?.message === 'string' && payloadData.message.indexOf('MissingGasEntitlementException') >= 0) {
        this.loginError = 'LOGIN.ERROR.MISSING_GAS_ENTITLEMENT';
      } else {
        this.loginError = 'LOGIN.ERROR.DEFAULT';
      }
    }
  }

  /**
   * Callback method that is called when the user session changed.
   *
   * @param cognitoUser - Cognito user object
   */
  private async onSessionChange(cognitoUser: CognitoUser | null): Promise<void> {
    this.logger.debug('userService.sessionObservable triggered', cognitoUser);

    // Re-route to login if cognito does not return a valid user.
    if (!cognitoUser) {
      return;
    }

    this.logger.debug('userService.sessionObservable ID token', await this.userService.getIdToken());

    // Check if a redirect URL pathname is stored in the 'redirectTo' key of the local storage.
    // If available, navigate to that pathname, if not, attempt to reroute based on the user's role.
    const redirectTo = localStorage.getItem('redirectTo');
    if (redirectTo && !redirectTo.endsWith('index.html')) {
      try {
        const wasNavigationSuccessful = await new Promise((resolve) => {
          this.ngZone.run(() => {
            this.router.navigate([redirectTo]).then(() => {
              resolve(true);
            }).catch(() => {
              resolve(false);
            });
          });
        });

        if (wasNavigationSuccessful) {
          return;
        }
        throw new Error('Navigation to redirectTo not successful');
      } catch (navigationError) {
        this.logger.warn('Invalid redirectTo value in local storage', redirectTo, navigationError);
        // Since routing based on `redirectTo` failed, fall back to standard routing (below).
      }
    }

    this.ngZone.run(() => {
      this.router.navigate(['/home']);
    });
  }

  public async loginWithMercedesBenz(): Promise<void> {
    this.isProcessing = true;

    try {
      const credentials: ICredentials = await this.userService.loginWithMercedesBenz();

      this.logger.debug('Login: Mercedes-Benz SSO login', credentials);
    } catch (loginError: any) {
      this.isProcessing = false;

      this.logger.error('Login: Mercedes-Benz SSO login failed', loginError);
    }
  }
}
