import { IUserRolesInput } from '@dep/common/interfaces';
import { UserStatus } from '@dep/common/user-api/types/user.type';

export class UserModel {
  constructor(
    public id: number,
    public sub: string,
    public username: string,
    public firstname: string,
    public lastname: string,
    public email: string,
    public phone: string,
    public jobtitle: string | null,
    public country: string,
    public language: string,
    public flags: string,
    public status: UserStatus,
    public lastlogin: string | null,
    public creationDate: string | null,
    public roles: IUserRolesInput[],
  ) { }
}
