import { Pipe, PipeTransform } from '@angular/core';
import { IOrgaentityAll } from '@dep/common/interfaces';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';

@Pipe({
  name: 'clientIsRCPSPlayer',
})
export class ClientIsRCPSPlayerPipe implements PipeTransform {
  constructor(
    private orgaentitiesService: OrgaentitiesService,
  ) { }

  /**
   * Check if the player is an RCPS player (`DEA_RCPS` starts with `ok: `).
   * This does not check the player type (Scala / RCPS).
   */
  public transform(item: IOrgaentityAll): boolean {
    const value = this.orgaentitiesService.getOEDataValue(item, 'DEA_RCPS');
    if (value && value.indexOf('ok: ') === 0) {
      return true;
    }
    return false;
  }
}
