<div class="content-block content-block-padding content-block-height">
  <h1 translate>ADMIN_PAGES.CONFIGURATION.TITLE</h1>
  <div class="alert alert-danger">
    Do not change a configuration parameter unless you know exactly what you are doing. Changes are in effect immediately.
  </div>
  <div class="row mt-3">
    <div class="col-4 col-sm-5 text-right label">
      <p><span translate>ADMIN_PAGES.CONFIGURATION.MAINTENANCE_MODE</span></p>
    </div>
    <div class="col-8 col-sm-7">
      <p class="mt-1 mb-2">
        <button type="button" class="btn btn-success btn-sm" (click)="setConfig('MAINTENANCE_MODE', 'true')" [disabled]="getConfigValue('MAINTENANCE_MODE') === 'true' || loading['MAINTENANCE_MODE']">{{ 'ADMIN_PAGES.CONFIGURATION.ENABLE' | translate }}</button>
        <button type="button" class="btn btn-danger btn-sm ml-2" (click)="setConfig('MAINTENANCE_MODE', 'false')" [disabled]="getConfigValue('MAINTENANCE_MODE') === 'false' || loading['MAINTENANCE_MODE']">{{ 'ADMIN_PAGES.CONFIGURATION.DISABLE' | translate }}</button>
      </p>
    </div>
  </div>
</div>
