import { Component, OnInit } from '@angular/core';
import { RoleRight } from '@dep/common/interfaces';

import { UserService } from '@dep/frontend/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connectedshowroom',
  templateUrl: './connectedshowroom.component.html',
  styleUrls: ['./connectedshowroom.component.scss'],
})
export class ConnectedshowroomComponent implements OnInit {
  public isLoading = false;

  /**
   * List of external/internal service tools.
   * `isRouterLink` - indicates internal tools (clients, reporting dashboard etc.)
   */
  public smartRetailHubTools: IRetailHubTools[] = [
    {
      title: 'ENERGY_MANAGEMENT',
      icon: 'energy_management_white.svg',
      url: '/clients',
      isRouterLink: true,
    },
    {
      title: 'SHOWROOM_CONNECT_FRONTEND',
      subtitle: 'ROOM_CONTROL',
      icon: 'showroom_connect_white.svg',
      url: environment.config.smartRetailHub.urls.roomControl,
    },
    {
      title: 'TAKEAWAY_VEHICLE_INFORMATION',
      subtitle: 'QR_CODE_GENERATOR',
      icon: 'qr_code.svg',
      url: '/connectedshowroom/qr-code-generator',
      isRouterLink: true,
    },
    {
      title: 'SHOWROOM_PRESENTER',
      icon: 'showroom_presenter_white.svg',
      url: '/files',
      isRouterLink: true,
    },
    {
      title: 'SUPPORT',
      icon: 'support_white.svg',
      url: '/supporttickets',
      isRouterLink: true,
    },
  ];

  constructor(
    private userService: UserService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    // Limit the tools to Energy Management and Support if the user has the `CONNECTEDSHOWROOM_LIMITED` right.
    const hasEnergyManagementRole = await this.userService.hasRight(RoleRight.CONNECTEDSHOWROOM_LIMITED);
    if (hasEnergyManagementRole) {
      this.smartRetailHubTools.forEach((tool) => {
        if (tool.title !== 'ENERGY_MANAGEMENT' && tool.title !== 'SUPPORT') {
          tool.url = '';
          tool.isRouterLink = undefined;
        }
      });
    }

    this.isLoading = false;
  }
}

interface IRetailHubTools {
  title: string,
  subtitle?: string,
  icon: string,
  url: string,
  isRouterLink?: boolean,
}
