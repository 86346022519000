import { Component } from '@angular/core';

import { ConfigService } from '@dep/frontend/services/config.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent {
  public loading: { [key: string]: boolean } = {};
  private configs?: { key: string, value: string }[];

  constructor(
    private configService: ConfigService,
  ) {
    this.configs = this.configService.list();

    this.configService.configsObservable$.subscribe({
      next: (data) => {
        if (!this.configs) {
          this.configs = [];
        }
        const index = this.configs.findIndex((val) => val.key === data.key);
        if (index >= 0) {
          this.configs[index] = data;
        } else {
          this.configs.push(data);
        }
      },
    });
  }

  public getConfigValue(key: string): string {
    if (this.configs) {
      const config = this.configs.find((r) => r.key === key)?.value;
      return config || '';
    }
    return '';
  }

  public async setConfig(key: string, newValue: string): Promise<void> {
    this.loading[key] = true;
    await this.configService.update(key, newValue);
    this.loading[key] = false;
  }
}
