<form class="stage-wall" #playerRcpsContentEditForm="ngForm">
  <div *ngIf="isLoading.editRcpsContent || hasError.editRcpsContent || isLoading.playerContent" class="overlay">
    <div *ngIf="isLoading.editRcpsContent || isLoading.playerContent" class="loading"></div>
    <div *ngIf="hasError.editRcpsContent" class="text-danger bg-white">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
  </div>
  <!-- Header -->
  <div class="modal-header p-0">
    <div class="modal-title row">
      <h4 class="col">{{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.TITLE' | translate }}</h4>
      <div class="d-inline-flex align-items-center mt-1 px-2">
        <img src="assets/icons/client_{{ popup?.settings?.customValues?.clientAbbreviation }}_{{ popup?.settings?.customValues?.clientColor }}.svg" class="client-icon pr-1" [title]="'ID: ' + popup?.settings?.customValues?.orgaentityId "/>
        <span>{{ popup?.settings?.customValues?.name }}</span>
      </div>
    </div>
  </div>
  <div class="col-12 row pt-3 modal-description-text">
    <p class="mb-0 mt-1">{{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.DESCRIPTION' | translate }}</p>
    <div class="ml-2 align-items-center" matTooltip="{{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.INFO' | translate }}">
      <img class="info-icon" src="assets/icons/info.svg" alt="information">
    </div>
  </div>
  <!-- Brand Names -->
  <div class="position-relative">
    <div class="btn-group row pl-2 mt-3 justify-content-center justify-content-sm-start d-none d-sm-flex">
      <ng-container *ngFor="let brand of allBrands; let i = index;">
        <div class="mr-3 mt-2">
          <button type="button" class="btn tab-btn-group tab-btn-group-primary" (click)="selectBrandRelatedContent(i)" [class.active-button-state]="brand.name === selectedBrand?.name">
            <img *ngIf="brand.name !== selectedBrand?.name" src="assets/icons/{{ brand.logoImage }}_light.svg" alt="Brand logo">
            <img *ngIf="brand.name === selectedBrand?.name" src="assets/icons/{{ brand.logoImage }}_white.svg" alt="Brand logo">
          </button>
        </div>
      </ng-container>
    </div>
    <!-- Replace button group with ng-select for small screen-->
    <div class="col-12 px-0 py-3 d-sm-none">
      <ng-select
        *ngIf="selectedBrand"
        name="selectBrand"
        [items]="selectBrandData"
        [ngModel]="selectedBrand.name"
        (ngModelChange)="onBrandSelectionChange($event)"
        [clearable]="false"
        [searchable]="false"
      >
      </ng-select>
    </div>
    <!-- Car Type Tabs -->
    <mat-tab-group animationDuration="600ms" class="my-4" [(selectedIndex)]="carTypeTabSelectedIndex">
      <ng-container *ngFor="let carType of selectedBrand?.carTypes; let i = index;">
        <mat-tab label="{{ carType.name }}">
          <div class="row col-12 my-4">
            <ng-container *ngFor="let content of carType.contents; let i = index;">
              <!-- Image Grid -->
              <button type="button" class="mr-3 mt-2 mb-2 px-0 pb-2 d-flex flex-column image-grid" [class.image-grid-active]="content.playlist === selectedPlaylist" (click)="selectPlaylist(content.playlist)" [attr.data-playlist]="content.playlist">
                <div class="mb-2" style="background: url('../assets/icons/stagewall-content-models/{{ content.image }}.jpg') no-repeat 0 0; background-size: auto; width: 200px; height: 125px"></div>
                <div class="d-flex px-2" style="width: 200px;">
                  <div class="text-left" style="width: 150px;"><span style="font-family: 'MBCorpoATitleCond'; font-size: 1.125rem;">{{ content.modelName }}</span></div>
                  <div class="ml-1 d-inline-flex align-items-center"><img src="assets/icons/check_circle_blue.svg" alt="Check Circle"></div>
                </div>
              </button>
            </ng-container>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>

  <!-- Content Throttling Container -->
  <div class="d-flex mb-4">
    <div class="d-inline-flex mr-0 mr-sm-3">
      <mat-slide-toggle [(ngModel)]="isContentThrottling" name="contentThrottling" [disabled]="isContentThrottling === undefined">
        {{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING' | translate }}
      </mat-slide-toggle>
      <div class="ml-2 align-items-center" matTooltip="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_INFO' | translate }}">
        <img class="info-icon" style="vertical-align: text-bottom;" src="assets/icons/info.svg" alt="information">
      </div>
    </div>
    <div>
      <span *ngIf="!isSuccessSaved.throttling" class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
      <span *ngIf="hasError?.throttling" class="text-danger" style="opacity: 1">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</span>
    </div>
    <div *ngIf="isLoading.throttling" class="loading"></div>
  </div>
  <!-- Button Container -->
  <hr style="background-color: #4F4F4F; margin-right: -44px; margin-left: -44px;">
  <div class="text-right pt-4">
    <button
      type="button"
      class="btn btn-secondary mr-3"
      [disabled]="isFormDisabled || isLoading.throttling || isLoading.editRcpsContent"
      (click)="closePopup()"
    >{{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.CANCEL' | translate }}</button>
    <button
      type="submit"
      (click)="saveData(playerRcpsContentEditForm?.form)"
      [disabled]="isLoading.throttling || isLoading.editRcpsContent || isFormDisabled || !selectedPlaylist"
      class="btn btn-primary"
    >{{ 'ADMIN_PAGES.CLIENTS.PLAYER_RCPS_CONTENT_EDIT_MODAL.SELECT' | translate }}</button>
  </div>
</form>
