import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';

import { environment } from '@dep/frontend/../environments/environment';
import { UserService } from '@dep/frontend/services/user.service';

type CognitoUserSession = NonNullable<ReturnType<CognitoUser['getSignInUserSession']>>;
type CognitoIdToken = ReturnType<CognitoUserSession['getIdToken']>;
type CognitoAccessToken = ReturnType<CognitoUserSession['getAccessToken']>;

@Component({
  selector: 'app-licenses-assignment',
  templateUrl: './licenses-assignment.component.html',
  styleUrls: ['./licenses-assignment.component.scss'],
})
export class LicensesAssignmentComponent implements OnInit {
  public isLoading = false;
  public lmsAssignLicensesUrl: SafeUrl;
  private accessToken?: CognitoAccessToken | null;
  private idToken?: CognitoIdToken | null;
  private playername?: string;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {
    this.lmsAssignLicensesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.config.lms.url + '/assign-licenses');
  }

  public async ngOnInit(): Promise<void> {
    this.accessToken = await this.userService.getAccessToken();
    this.idToken = await this.userService.getIdToken();
    this.playername = this.route.snapshot.params.playername;
  }

  public passAuth(): void {
    const iframe = document.getElementById('license-management-server');
    if (iframe === null) {
      return;
    }

    const iWindow = (iframe as HTMLIFrameElement).contentWindow;
    if (iWindow) {
      iWindow.postMessage(
        {
          accessToken: this.accessToken,
          idToken: this.idToken,
          playername: this.playername,
        },
        environment.config.lms.url + '/assign-licenses',
      );
    }
  }
}
