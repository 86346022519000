import {
  type OrgaentityLocationContact,
  OrgaentityType,
  type OrgaentityUpdate,
} from '@dep/common/core-api/types/orgaentity.type';

export class ClientModel {
  public data?: Record<string, string> | null;
  public dataEnhanced?: Record<string, string> | null;

  constructor(
    /** If `-1` or `undefined`, the client does not exist yet. */
    public id: number | undefined,
    public name?: string,
    public type?: '' | 'COMPANY' | 'LOCATION' | 'CLIENT' | 'CARSHOWADAPTER' | 'MATCHMAKER' | 'HANDOVERCOMPANION' | 'CARMEDIASTICK' | 'SHOWROOMCONNECT',
    public orgaentitySubtypeId?: number | null,
    public gssn?: string | null,
    public brandCode?: string | null,
    public level?: string | null,
    public parentOrgaentityId?: number | null,
    public validUntil?: string,
    public division?: string | null,
    public playername?: string | null,
    public companyType?: string | null,
    public street?: string | null,
    public city?: string | null,
    public zip?: string | null,
    public country?: string | null,
    public contacts?: OrgaentityLocationContact[] | null,
    public mediaintegrator?: string | null,
    public startDate?: string,
    public registrationDate?: string,
    public hostname?: string,
    public hardwarePlatine?: string,
    public appleId1?: string | null,
    public appleId2?: string | null,
  ) { }
}

/**
 * Convert data from `OrgaentityUpdate` to the legacy `ClientModel`.
 *
 * Can be removed once no method requires the `ClientModel` anymore.
 */
export function createClientModelFromOrgaentity(input: OrgaentityUpdate): ClientModel {
  let model: ClientModel = {
    id: input.id,
    name: input.name,
    type: input.type,
    orgaentitySubtypeId: input.orgaentitiesSubtypesId,
    gssn: input.gssn,
    brandCode: input.brandCode,
    level: input.level,
    parentOrgaentityId: input.parentOrgaentitiesId,
  };

  if (input.type === OrgaentityType.Company) {
    model = {
      ...model,
      companyType: input.companyType,
    };
  } else if (input.type === OrgaentityType.Location) {
    model = {
      ...model,
      street: input.street,
      city: input.city,
      zip: input.zip,
      country: input.country,
      contacts: input.contacts,
      mediaintegrator: input.mediaintegrator,
    };
  } else if (input.type === OrgaentityType.Client) {
    model = {
      ...model,
      validUntil: input.validUntil,
      division: input.division,
      playername: input.hostname,
    };
  } else if (input.type === OrgaentityType.CarshowAdapter) {
    model = {
      ...model,
      startDate: input.startDate,
      validUntil: input.validUntil,
      appleId1: input.appleid1,
      appleId2: input.appleid2,
    };
  } else if (input.type === OrgaentityType.Carmediastick) {
    model = {
      ...model,
      registrationDate: input.registrationDate,
      hostname: input.hostname,
      hardwarePlatine: input.hardwarePlatine,
    };
  }

  return model;
}
