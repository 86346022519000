<div *ngIf="isLoading" class="loading loading-center mt-5"></div>
<iframe
  *ngIf="lmsListLicensesUrl"
  (load)="passAuth()"
  id="license-management-server"
  scrolling="no"
  width="100%"
  height="100%"
  frameBorder="0"
  [src]="lmsListLicensesUrl"
></iframe>