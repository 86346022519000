export interface IContentItem {
  modelName: string;
  playlist: string;
  image: string;
}

export interface ICarType {
  name: string;
  contents: IContentItem[];
}

export interface IBrand {
  name: string;
  logoImage: string;
  carTypes: ICarType[];
}

export const modelPlaylistMapping: IBrand[] = [
  {
    name: 'MB', // name of the brand (Mercedes Benz, AMG, MercedesBenz-EQ etc.)
    logoImage: 'mb_logo',
    carTypes: [ // Types of Car (Limousine, SUV, VAN)
      {
        name: 'Limousine',
        contents: [
          {
            modelName: 'A-Class Limousine',
            playlist: 'A_CLASS_LIMOUSINE',
            image: 'A_Class_Limousine',
          },
          {
            modelName: 'C-Class Limousine',
            playlist: 'C_CLASS_LIMOUSINE',
            image: 'C_Class_Limousine',
          },
          {
            modelName: 'E-Class Limousine',
            playlist: 'E_CLASS_LIMOUSINE',
            image: 'E_Class_Limousine',
          },
          {
            modelName: 'S-Class Limousine',
            playlist: 'S_CLASS_LIMOUSINE',
            image: 'S_Class_Limousine',
          },
        ],
      },
      {
        name: 'SUVs',
        contents: [
          {
            modelName: 'G-Class',
            playlist: 'G_CLASS',
            image: 'G_Class',
          },
          {
            modelName: 'GLA',
            playlist: 'GLA',
            image: 'GLA',
          },
          {
            modelName: 'GLB',
            playlist: 'GLB',
            image: 'GLB',
          },
          {
            modelName: 'GLC',
            playlist: 'GLC',
            image: 'GLC',
          },
          {
            modelName: 'GLE',
            playlist: 'GLE',
            image: 'GLE',
          },
          {
            modelName: 'GLS',
            playlist: 'GLS',
            image: 'GLS',
          },
          {
            modelName: 'GLC Coupé',
            playlist: 'GLC_COUPE',
            image: 'GLC_Coupe',
          },
          {
            modelName: 'GLE Coupé',
            playlist: 'GLE_COUPE',
            image: 'GLE_Coupe',
          },
        ],
      },
      {
        name: 'Hatchback',
        contents: [
          {
            modelName: 'A-Class',
            playlist: 'A_CLASS',
            image: 'A_Class',
          },
          {
            modelName: 'B-Class',
            playlist: 'B_CLASS',
            image: 'B_Class',
          },
        ],
      },
      {
        name: 'Estate',
        contents: [
          {
            modelName: 'C-Class All-Terrain',
            playlist: 'C_CLASS_ALL_TERRAIN',
            image: 'C_Class_All_Terrain',
          },
          {
            modelName: 'C-Class Estate',
            playlist: 'C_CLASS_ESTATE',
            image: 'C_Class_Estate',
          },
          {
            modelName: 'CLA Shooting Brake',
            playlist: 'CLA_SB',
            image: 'CLA_Shooting_Brake',
          },
          {
            modelName: 'E-Class All-Terrain',
            playlist: 'E_CLASS_ALL_TERRAIN',
            image: 'E_Class_All_Terrain',
          },
          {
            modelName: 'E-Class Estate',
            playlist: 'E_CLASS_ESTATE',
            image: 'E_Class_Estate',
          },
        ],
      },
      {
        name: 'Coupé',
        contents: [
          {
            modelName: 'C-Class Coupé',
            playlist: 'C_CLASS_COUPE',
            image: 'C_Class_Coupe',
          },
          {
            modelName: 'E-Class Coupé',
            playlist: 'E_CLASS_COUPE',
            image: 'E_Class_Coupe',
          },
          {
            modelName: 'CLA',
            playlist: 'CLA',
            image: 'CLA',
          },
          {
            modelName: 'CLS',
            playlist: 'CLS',
            image: 'CLS',
          },
        ],
      },
      {
        name: 'Cabrio',
        contents: [
          {
            modelName: 'C-Class Cabrio',
            playlist: 'C_CLASS_CABRIO',
            image: 'C_Class_Cabrio',
          },
          {
            modelName: 'E-Class Cabrio',
            playlist: 'E_CLASS_CABRIO',
            image: 'E_Class_Cabrio',
          },
          {
            modelName: 'SL',
            playlist: 'SL',
            image: 'SL',
          },
        ],
      },
      {
        name: 'Vans',
        contents: [
          {
            modelName: 'V-Class',
            playlist: 'V_CLASS',
            image: 'V_Class',
          },
        ],
      },
    ],
  },
  {
    name: 'MBEQ',
    logoImage: 'mbeq_logo',
    carTypes: [
      {
        name: 'Limousine',
        contents: [
          {
            modelName: 'EQE',
            playlist: 'EQE',
            image: 'EQE',
          },
          {
            modelName: 'EQS',
            playlist: 'EQS',
            image: 'EQS',
          },
        ],
      },
      {
        name: 'SUVs',
        contents: [
          {
            modelName: 'EQA',
            playlist: 'EQA',
            image: 'EQA',
          },
          {
            modelName: 'EQB',
            playlist: 'EQB',
            image: 'EQB',
          },
          {
            modelName: 'EQC',
            playlist: 'EQC',
            image: 'EQC',
          },
          {
            modelName: 'EQS SUV',
            playlist: 'EQS_SUV',
            image: 'EQS_SUV',
          },
        ],
      },
      {
        name: 'Vans',
        contents: [
          {
            modelName: 'EQV',
            playlist: 'EQV',
            image: 'EQV',
          },
        ],
      },
    ],
  },
  {
    name: 'AMG',
    logoImage: 'amg_logo',
    carTypes: [
      {
        name: 'Coupé',
        contents: [
          {
            modelName: 'AMG GT 4-Door Coupé',
            playlist: 'AMG_GT_4',
            image: 'AMG_GT_4_Door_Coupe',
          },
        ],
      },
    ],
  },
  {
    name: 'MAYBACH',
    logoImage: 'maybach_logo',
    carTypes: [
      {
        name: 'Limousine',
        contents: [
          {
            modelName: 'Maybach S-Class',
            playlist: 'MAYBACH_S_CLASS',
            image: 'Maybach_S_Class',
          },
        ],
      },
      {
        name: 'SUVs',
        contents: [
          {
            modelName: 'Maybach GLS',
            playlist: 'MAYBACH_GLS',
            image: 'Maybach_GLS',
          },
        ],
      },
    ],
  },
];

/**
 * Gets brand data for ng-select dropdown.
 * @returns key-value pair object
 * @example { MB: 'Mercedes-Benz' }
 */
export function getSelectBrandsData(): { [key: string]: string } {
  return {
    MB: 'Mercedes-Benz',
    MBEQ: 'Mercedes-EQ',
    AMG: 'Mercedes-AMG',
    MAYBACH: 'Mercedes-Maybach',
  };
}

/**
 * Gets brand name, model name and index from `const` modelPlaylistMapping for a given playlist
 * @param playlist Playlist name
 * @returns `{ brandName: string, modelName: string, tabIndex: number }` or Null
 * @example
 * ```ts
 * // Given playlist "AMG_GT_4"
 * // returns
 * {
 *   brandName: 'AMG',
 *   modelName: 'AMG GT 4-Door Coupé',
 *   tabIndex: 0
 * }
 * ```
 */
export function getPlaylistInfo(playlist: string): { brandName: string, modelName: string, tabIndex: number } | null {
  for (const item of modelPlaylistMapping) {
    for (const [index, carType] of item.carTypes.entries()) {
      for (const c of carType.contents) {
        if (c.playlist === playlist) {
          return {
            brandName: item.name,
            modelName: c.modelName,
            tabIndex: index,
          };
        }
      }
    }
  }
  return null;
}
