<div class="users-details content-block content-block-padding content-block-height">
  <h1>
    @if (canEdit && userId === -1) {
      {{ 'ADMIN_PAGES.USERS.DETAILS.CREATE_USER' | translate }}
    } @else if (canEdit && userId !== -1) {
      {{ 'ADMIN_PAGES.USERS.DETAILS.EDIT_USER' | translate }}
    } @else if (!canEdit) {
      {{ 'ADMIN_PAGES.USERS.DETAILS.SHOW_USER' | translate }}
    }
  </h1>

  <div *ngIf="isUserLoading || isUserSaving || userDeleteInProgress" class="loading-overlay">
    <div class="loading"></div>
  </div>

  <form id="userForm" (ngSubmit)="save()" #userForm="ngForm">
    <!-- Activate user button -->
    <div *ngIf="model.status === UserStatus.Pending" class="row">
      <div class="col-12 text-center">
        <button
          type="button"
          class="btn btn-warning btn-sm"
          [disabled]="!canEdit || !model.roles || model.roles.length < 0"
          (click)="activateUser()"
        >{{ 'ADMIN_PAGES.USERS.DETAILS.ACTIVATE_USER' | translate }}</button>
        <span class="ml-1" style="font-size: 0.8rem;">{{ 'ADMIN_PAGES.USERS.DETAILS.RESET_PASSWORD_HINT' | translate }}</span>
      </div>
    </div>
    <!-- External related services (Alice and Cognito) -->
    <div class="form-group mt-4">

    </div>

    <div class="form-row">
      <div class="form-group col-md-9 mb-1">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.USERNAME' | translate }}</p>
        <input
          type="text"
          class="form-control form-control-sm"
          name="username"
          placeholder=""
          disabled="disabled"
          [value]="model.username"
        />
      </div>
      <div class="form-group col-md-3 mb-1">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.STATUS' | translate }}*</p>
        <ng-select
          name="status"
          [(ngModel)]="model.status"
          [items]="selectStatus"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-12 mb-0">
        @if (!isUserLoading) {
          <p class="pl-1">⤷
            @if (mercedesBenzId) {
              <a [href]="'https://alice.mercedes-benz.com/access/insights/user/' + mercedesBenzId" target="_blank" rel="noopener noreferrer">🔗 Alice Access</a>
            }
            <a [href]="'https://eu-central-1.console.aws.amazon.com/cognito/users/?region=eu-central-1#/pool/' + userPoolId + '/users/' + model.username" target="_blank" rel="noopener noreferrer" class="ml-2">🔗 Amazon Cognito</a>
          </p>
        }
      </div>
    </div>

    <div class="form-group" *ngIf="isSsoUser === false">
      <p>{{ 'ADMIN_PAGES.USERS.DETAILS.PASSWORD' | translate }}</p>
      <p class="mt-1 mb-2"><button type="button" class="btn btn-warning btn-sm btn-icon" [disabled]="!canEdit || !userForm.form.valid" (click)="resetPassword()">{{ 'ADMIN_PAGES.USERS.DETAILS.RESET_PASSWORD' | translate }}</button> <span class="ml-1">{{ 'ADMIN_PAGES.USERS.DETAILS.RESET_PASSWORD_HINT' | translate }}</span></p>
    </div>

    <hr class="my-4">

    <div class="form-group" [class.form-row-error]="mail.invalid && mail.dirty && mail.touched">
      <p>{{ 'ADMIN_PAGES.USERS.DETAILS.EMAIL' | translate }}*</p>
      <input
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="model.email"
        name="mail"
        #mail="ngModel"
        required
        placeholder=""
        [readonly]="isSsoUser === true"
      />
    </div>

    <div class="form-row">
      <div class="form-group col-md-6" [class.form-row-error]="firstName.invalid && firstName.dirty && firstName.touched">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.FIRST_NAME' | translate }}*</p>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="model.firstname"
          name="firstName"
          #firstName="ngModel"
          required
          placeholder=""
          [readonly]="isSsoUser === true"
        />
      </div>
      <div class="form-group col-md-6" [class.form-row-error]="lastName.invalid && lastName.dirty && lastName.touched">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.LAST_NAME' | translate }}*</p>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="model.lastname"
          name="lastName"
          #lastName="ngModel"
          required
          placeholder=""
          [readonly]="isSsoUser === true"
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6" [class.form-row-error]="phone.invalid && phone.dirty && phone.touched">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.PHONE' | translate }}</p>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="model.phone"
          name="phone"
          #phone="ngModel"
          placeholder=""
        />
      </div>
      <div class="form-group col-md-6" [class.form-row-error]="jobtitle.invalid && jobtitle.dirty && jobtitle.touched">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.JOBTITLE' | translate }}</p>
        <input
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="model.jobtitle"
          name="jobtitle"
          #jobtitle="ngModel"
          placeholder=""
        />
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.COUNTRY' | translate }}</p>
        <ng-select
          name="countries"
          [(ngModel)]="model.country"
          [items]="selectCountries"
          [readonly]="isSsoUser === true"
          [clearable]="false"
        >
        </ng-select>
      </div>
      <div class="form-group col-md-6">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.LANGUAGE' | translate }}</p>
        <ng-select
          name="languages"
          [(ngModel)]="model.language"
          [items]="selectLanguages"
          [clearable]="false"
        >
        </ng-select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.CREATION_DATE' | translate }}</p>
        <input
          type="text"
          class="form-control form-control-sm"
          [value]="model.creationDate | stringToDatetime"
          required
          placeholder="None"
          readonly
          disabled
        />
      </div>
      <div class="form-group col-md-6">
        <p>{{ 'ADMIN_PAGES.USERS.DETAILS.LASTLOGIN' | translate }}</p>
        <input
          type="text"
          class="form-control form-control-sm"
          [value]="model.lastlogin | stringToDatetime"
          placeholder="None"
          readonly
          disabled
        />
      </div>
    </div>

    <hr class="my-4">

    <div class="form-group">
      <h5>{{ 'ADMIN_PAGES.USERS.DETAILS.ROLES' | translate }}</h5>
      <div *ngIf="hasFaultyRoles">
        <div class="alert alert-danger" role="alert">
          The roles of this user seem to be damaged.
        </div>
      </div>

      <!-- TODO: Display requested roles information? -->
      <!-- <div class="mb-4">
        <div class="mb-paper py-2 px-3">
          <p><strong>Requested Roles</strong> (Last Request: 2024-04-17)</p>
          <ul class="mb-1">
            <li>Role 1</li>
            <li>Role 2</li>
          </ul>
        </div>
      </div> -->

      <div *ngIf="isOrgaentitiesLoading || isRolesLoading || isUserRolesLoading" class="loading"></div>

      <ng-container *ngIf="!isOrgaentitiesLoading && !isRolesLoading && !isUserRolesLoading">
        <div *ngFor="let userRole of model.roles; let i = index" class="row mt-2 mb-4">
          <div class="col-6">
            <ng-select
              name="urRole{{ i }}"
              [items]="selectRoles || []"
              [disabled]="!selectRoles"
              [(ngModel)]="userRole.roleId"
              (change)="onRoleSelect($event.value, i)"
              [clearable]="false"
            ></ng-select>
          </div>
          <div class="col-6">
            <div *ngFor="let oeId of userRole.orgaentitiesIds; let j = index" class="d-flex pb-3">
              <ng-select
                name="urOE{{ i }}-{{ j }}"
                [items]="selectOrgaentities || []"
                [disabled]="!selectRoles"
                [(ngModel)]="userRole.orgaentitiesIds[j]"
                (change)="onOrgaentitySelect($event.value, i, j)"
                style="width: 90%;"
                [clearable]="false"
              ></ng-select>
              <a class="ml-1 ml-md-3 img-wrapper" href="javascript: void(0)" (click)="onOrgaentityRemove(i, j)"><img src="assets/icons/delete.svg" title="Remove organizational entity from role" /></a>
            </div>
            <p><a class="primary" href="javascript: void(0)" (click)="addOrgaentityToRole(i)"><img class="align-baseline" src="assets/icons/plus_blue.svg" alt="Add organizational entity to role" height="13px"> Add organizational entity to role</a></p>
          </div>
        </div>
        <p *ngIf="model.roles"><a class="primary" href="javascript: void(0)" (click)="addRole()"><img class="align-baseline" src="assets/icons/plus_blue.svg" alt="Add role" height="13px"> Add role</a></p>
      </ng-container>
    </div>
    <hr class="my-3">
    <div class="form-group">
      <h5>{{ 'ADMIN_PAGES.USERS.DETAILS.CONTACTS' | translate }}</h5>
      <div *ngIf="isContactsLoading" class="loading"></div>
      <ng-container *ngIf="!isContactsLoading">
        <div *ngFor="let contact of contacts"><a [routerLink]="['/contacts', contact.id]">{{ getContactText(contact) }}</a></div>
      </ng-container>
    </div>
    <hr class="my-5" style="margin-left: -20px; margin-right: -20px;">

    <div class="content-block-controls row">
      <div class="col-12 text-center text-sm-right">
        <div *ngIf="(RoleRight.USER_DELETE | userHasRight: undefined : '/1/') | async" class="col-12 col-sm-6 pl-0 mb-2 mb-sm-0 text-center text-sm-left float-left">
          <button type="button" *ngIf="userId !== -1" class="btn btn-danger btn-sm mr-2" (click)="deleteUser()" [disabled]="!canEdit">{{ 'ADMIN_PAGES.USERS.DETAILS.DELETE' | translate }}</button>
        </div>
        <button type="button" class="btn btn-secondary btn-sm mr-3" (click)="cancel()">{{ 'ADMIN_PAGES.USERS.DETAILS.CANCEL' | translate }}</button>
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="!canEdit || !userForm.form.valid">{{ 'ADMIN_PAGES.USERS.DETAILS.SAVE_USER' | translate }}</button>
      </div>
    </div>

  </form>
</div>

<app-info-modal
  *ngIf="confirmModal"
  [visible]="confirmModal.visible"
  [dialogType]="confirmModal.dialogType"
  [title]="confirmModal.title"
  [content]="confirmModal.content"
  [confirm]="confirmModal.confirm ? confirmModal.confirm.bind(this) : undefined"
  [confirmButtonText]="confirmModal.confirmButtonText || ''"
  [dismiss]="confirmModal.dismiss ? confirmModal.dismiss.bind(this) : undefined"
  [dismissButtonText]="confirmModal.dismissButtonText || ''"
></app-info-modal>
