<div class="users-list content-block content-block-padding content-block-height">
  <h1 translate>ADMIN_PAGES.USERS.LIST.TITLE</h1>
  <form class="content-block-bar row no-gutters" (submit)="search()">
    <div class="col-5">
      <div class="search-input-container">
        <input name="searchTermInput" class="form-control form-control-sm" type="search" [(ngModel)]="searchTermInput" (search)="onSearchTermInputSearch()" placeholder="{{ 'ADMIN_PAGES.USERS.LIST.SEARCH' | translate }}" />
        <img src="assets/icons/search.svg" class="search-input-button" (click)="search()">
      </div>
    </div>
    <div class="col-7">
      <!-- <button [routerLink]="['/users', 'create']" class="btn btn-secondary btn-sm" translate> + <span class="d-none d-md-inline-block" translate>ADMIN_PAGES.USERS.LIST.CREATE_USER</span><span class="d-md-none" translate>ADMIN_PAGES.CREATE</span></button> -->
      <div class="row mt-2 justify-content-end">
        <a class="primary" [routerLink]="['/login', 'register', 'internal']" target="_blank" rel="noopener noreferrer">🗗 <span translate>ADMIN_PAGES.USERS.LIST.REGISTER_USER</span></a>
        <a *ngIf="(RoleRight.USER_READ | userHasRight: undefined : '/1/') | async" class="ml-3 primary" href="javascript: void(0);" (click)="downloadExport()" [title]="'ADMIN_PAGES.USERS.LIST.EXPORT_USERS' | translate">↓ <span>{{ 'ADMIN_PAGES.USERS.LIST.EXPORT_USERS' | translate }}</span></a>
      </div>
    </div>
  </form>
  <div>
    <div *ngIf="isLoading" class="loading loading-center mt-5"></div>
    <table class="table table-sm table-admin" [class.isLoading]="isLoading">
      <thead>
        <tr>
          <th class="border-top-0 border-bottom-0" scope="col" translate>ADMIN_PAGES.USERS.LIST.ID</th>
          <th class="border-top-0 border-bottom-0" scope="col" translate>ADMIN_PAGES.USERS.LIST.USERNAME</th>
          <th class="border-top-0 border-bottom-0" scope="col" translate>ADMIN_PAGES.USERS.LIST.NAME</th>
          <th class="border-top-0 border-bottom-0" scope="col" translate>ADMIN_PAGES.USERS.LIST.EMAIL</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="list && list.items.length > 0">
          <ng-container *ngFor="let user of list.items; let i = index;">
            <tr
              *ngIf="i >= getLowerPageBound() && i <= getUpperPageBound()"
              [routerLink]="['/users', user.id]"
            >
              <td>
                <a [routerLink]="['/users', user.id]">{{ user.id }}</a>
              </td>
              <td>
                {{ user.username }}
                @if (user.status === 'PENDING' || user.status === 'PENDING_ALICE') {
                  ⌛
                } @else if (user.status === 'PASSWORD_RESET_REQUIRED') {
                  ⚠️
                } @else if (user.status === 'DISABLED') {
                  ❌
                }
                @if (!user.sub) {
                  <img src="assets/icons/warning.svg" class="warning-icon" title="User has no sub" />
                }
              </td>
              <td>{{ user.lastname }}, {{ user.firstname }}</td>
              <td class="break-all">{{ user.email }}</td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(list && list.items.length === 0 && !isLoading) || error">
          <tr>
            <td colspan="5">
              <p *ngIf="!error" translate class="text-center mt-2">ADMIN_PAGES.USERS.LIST.NO_USERS</p>
              <p *ngIf="error" translate class="text-center mt-2">ADMIN_PAGES.LOADING_FAILED</p>
            </td>
          </tr>
        </ng-container>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5">
            <p class="text-right mb-0">
              <app-pagination [list]="this" itemName="{{ 'ADMIN_PAGES.USERS.LIST.PAGES_USER' | translate }}"></app-pagination>
            </p>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>

<app-info-modal
  [visible]="showModal"
  title="{{ modalTitle | translate }}"
  content="{{ modalContent | translate }}"
  [dismiss]="onModalDismissFn.bind(this)"
></app-info-modal>
