<form #energyManagementForm="ngForm" *ngIf="popup && popup.settings.customValues">
  <div class="position-relative">
    <!-- Header -->
    <div class="modal-header p-0 border-0">
      <div class="modal-title row align-items-center">
        <h2 class="col" translate>ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.TITLE</h2>
        <div *ngIf="popup.settings.customValues.type === 'CLIENT'" class="px-2">
          <img src="assets/icons/client_{{ popup.settings.customValues.clientAbbreviation }}_{{ popup.settings.customValues.clientColor }}.svg" class="client-icon" [title]="'ID: ' + popup.settings.customValues.orgaentityId" />
          <span style="color: #9F9F9F;" class="ml-1 mr-2">{{ popup.settings.customValues.playername }}</span>
          <span *ngIf="isEnergySettingsPending" class="badge badge-warning">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.PENDING' | translate }}</span>
          <span *ngIf="!isSuccessSaved.energySettings" class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
        </div>
        <div *ngIf="popup.settings.customValues.type === 'LOCATION'" class="mt-1 px-2">
          <span style="color: #9F9F9F;">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOCATION' | translate }}: {{ popup.settings.customValues.name }}</span>
        </div>
      </div>
    </div>
    <!-- Energy Settings Container -->
    <div class="py-2">
      <div *ngIf="popup.settings.customValues.type === 'CLIENT'" class="row mb-4 align-items-baseline">
        <div class="col-md-4 mt-2">
          <mat-slide-toggle [(ngModel)]="isActiveHours" name="activeHours">
            <span class="container-title">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.ACTIVITY_PERIOD' | translate }}</span>
          </mat-slide-toggle>
          <span
            class="ml-2"
            matTooltip="{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.INFO' | translate }}"
          >
            <img src="assets/icons/info.svg" alt="information">
          </span>
        </div>
        <div *ngIf="isActiveHours" class="col-md-8 pr-3 text-md-right">
          <img class="mr-1 mb-1" src="assets/icons/undo.svg" alt="reverse">
          <a (click)="initEnergySettings()" class="primary">
            {{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.UNDO' | translate }}
          </a>
        </div>
      </div>
      <div *ngIf="popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.PlayersSelection" class="mt-2 d-flex flex-column" style="width:80%;">
        <h4> {{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.PLAYER_SELECTION' | translate }}: </h4>
        <ul class="pl-0 mt-2">
          <li class="row no-gutters" (click)="selectAllClients()">
            <input type="checkbox" #selectAllPlayers name="selectAllPlayers" [(ngModel)]="areAllClientsSelected">
            <label class="ml-4 mb-0 font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.ALL_PLAYERS' | translate }}</label>
          </li>
          <ng-container *ngFor="let client of clients">
            <li *ngIf="(client.client | clientIsIOTAssigned: true)" (click)="selectClient(client)" class="row no-gutters">
              <input type="checkbox" name="{{client.client.name}}" [value]="client.client.id" [checked]="client.selected">
              <span class="row ml-1">
                <div style="width: 18px;">
                  <img *ngIf="(client.client | clientIsEnergyManagementEnabled)" src="assets/icons/energy_conservation.png" class="client-icon-leaf mx-1 mt-1"/>
                </div>
                <span class="mx-1">
                  <img src="assets/icons/client_{{ client.client.playername | clientTypeAbbreviation }}_{{ client.client | clientIconColor }}.svg" class="client-icon"/>
                </span>
                <label class="form-check-label mb-0 font-weight-normal" for="flexCheckDefault">{{ client.client.name }}</label>
              </span>
            </li>
          </ng-container>
        </ul>
        <div class="mb-3 d-flex align-items-center">
          <img src="assets/icons/info.svg" alt="information">
          <div class="ml-2" [innerHTML]="'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.INFO_LEAF_ICON' | translate"></div>
        </div>
      </div>
      <div *ngIf="(isActiveHours && popup.settings.customValues.type === 'CLIENT') || (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings)">
        <div class="col-12 d-flex flex-wrap p-0">
          <div
            *ngFor="let row of activeHoursSelectValue; let i = index;"
            class="mr-3 mb-1 activity-window"
            style="box-shadow: 0px 6px 14px #00000042; background-color: #4F4F4F;"
          >
            <div class="row p-2" style="height: 45px;">
              <div class="col-9 font-weight-bold">
                {{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_ACTIVITY_CYCLE' | translate }}{{' ' + (i + 1)}}
              </div>
              <!-- Remove icon -->
              <div class="col-3 text-center pt-1">
                <a class="img-wrapper" *ngIf="activeHoursSelectValue.length > 0" href="javascript: void(0)" (click)="removeTimeSelectionRow(row)">
                  <img src="assets/icons/delete.svg" alt="Remove icon"/>
                </a>
              </div>
            </div>

            <!-- Weekday picker -->
            <div class="border-top-bottom">
              <div class="weekday-picker mb-4 ml-2">
                <input type="checkbox" id="weekday-mon-{{i}}" value="Monday" name="monday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Monday')" [disabled]="weekdayConfigured(row, 'Monday')">
                <label for="weekday-mon-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.MONDAY</label>
                <input type="checkbox" id="weekday-tue-{{i}}" value="Tuesday" name="tuesday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Tuesday')" [disabled]="weekdayConfigured(row, 'Tuesday')">
                <label for="weekday-tue-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.TUESDAY</label>
                <input type="checkbox" id="weekday-wed-{{i}}" value="Wednesday" name="wednesday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Wednesday')" [disabled]="weekdayConfigured(row, 'Wednesday')">
                <label for="weekday-wed-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.WEDNESDAY</label>
                <input type="checkbox" id="weekday-thu-{{i}}" value="Thursday" name="thursday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Thursday')" [disabled]="weekdayConfigured(row, 'Thursday')">
                <label for="weekday-thu-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.THURSDAY</label>
                <input type="checkbox" id="weekday-fri-{{i}}" value="Friday" name="friday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Friday')" [disabled]="weekdayConfigured(row, 'Friday')">
                <label for="weekday-fri-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.FRIDAY</label>
                <input type="checkbox" id="weekday-sat-{{i}}" value="Saturday" name="saturday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Saturday')" [disabled]="weekdayConfigured(row, 'Saturday')">
                <label for="weekday-sat-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.SATURDAY</label>
                <input type="checkbox" id="weekday-sun-{{i}}" value="Sunday" name="sunday_{{i}}" (change)="onActiveHoursWeekdayChange(row, $event)" [checked]="row.days.includes('Sunday')" [disabled]="weekdayConfigured(row, 'Sunday')">
                <label for="weekday-sun-{{i}}" translate>ADMIN_PAGES.CLIENTS.DETAILS.SUNDAY</label>
              </div>
            </div>

            <div class="col-12">
              <app-time-range-slider
                [(startTime)]="row.start"
                [(endTime)]="row.end"
              ></app-time-range-slider>
            </div>
          </div>
          <!-- Add item text -->
          <div
            *ngIf="weekDaysSelected.length < 7"
            class="d-inline-flex align-items-center justify-content-center my-1 mb-1 activity-window"
            style="background-color: rgba(26, 26, 26, 0.5);"
          >
            <button type="button" (click)="addTimeSelectionRow()" class="btn btn-link p-0" style="box-shadow: 0px 6px 14px #00000045; border-radius: 50%;">
              <div
                class="d-inline-flex align-items-center justify-content-center"
                style="width: 100px; height: 100px; background-color: #333333; border-radius: 50%;"
              >
                <img src="assets/icons/plus_white.svg" alt="plus">
              </div>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="isLoading.energySettings || hasError.energySettings || isLoading.clients" class="overlay">
        <div *ngIf="isLoading.energySettings || isLoading.clients" class="loading"></div>
        <div *ngIf="hasError.energySettings" class="text-danger font-weight-bold">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
      </div>
    </div>
    <!-- Content Throttling Container -->
    <div *ngIf="popup.settings.customValues.type === 'CLIENT' || (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings)" class="py-4">
      <div>
        <mat-slide-toggle [(ngModel)]="isContentThrottling" name="contentThrottling" [disabled]="isContentThrottling === undefined">
          <span *ngIf="popup.settings.customValues.type === 'CLIENT'" class="container-title">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING'| translate }}</span>
          <span *ngIf="popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings" class="font-weight-lighter">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_ALL' | translate }}</span>
        </mat-slide-toggle>
        <span
          class="ml-2"
          matTooltip="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_INFO' | translate }}"
        >
          <img src="assets/icons/info.svg" alt="information">
        </span>
        <span *ngIf="!isSuccessSaved.throttling" class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
      </div>
      <div *ngIf="isLoading.throttling || hasError?.throttling" class="overlay">
        <div *ngIf="isLoading.throttling" class="loading"></div>
        <div *ngIf="hasError?.throttling" class="text-danger" style="opacity: 1">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
      </div>
    </div>
  </div>
  <hr class="mb-2" style="margin-left: -44px;margin-right: -44px;">
  <!-- Button Container -->
  <div *ngIf="popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.PlayersSelection" class="modal-footer border-0 p-0 pt-1">
    <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
    <button
      type="button"
      (click)="proceedToEnergySettingsStep()"
      [disabled]="isNextButtonDisabled"
      class="btn btn-primary"
    >{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.CONTINUE' | translate }}</button>
  </div>
  <div *ngIf="popup.settings.customValues.type === 'CLIENT' || (popup.settings.customValues.type === 'LOCATION' && currentStep === energyManagementLocationDialogStep.EnergySettings) " class="d-flex justify-content-end p-3">
    <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
    <button
      type="submit"
      (click)="saveData(energyManagementForm?.form)"
      [disabled]="isLoading.throttling || isLoading.energySettings || hasError.throttling || hasError.energySettings || (isActiveHours && (isFormDisabled || !energyManagementForm.valid || reqInputIncomplete()))"
      class="btn btn-primary ml-3"
    >{{ 'ADMIN_PAGES.SAVE' | translate }}</button>
  </div>
</form>
