<form *ngIf="form" [formGroup]="form">
  <!-- Header -->
  <div class="modal-header p-0 mb-3 border-0">
    <div class="modal-title row">
      <h3 class="col">
        {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.TITLE' | translate }}
      </h3>
      <div *ngIf="popup && popup.settings.customValues" class="mt-2 px-2">
        <img
          src="assets/icons/client_{{ popup.settings.customValues.clientAbbreviation }}_{{ popup.settings.customValues.clientColor }}.svg"
          class="client-icon mr-2"
          [title]="'ID: ' + popup.settings.customValues.orgaentityId"
        />
        <span style="color: #9F9F9F;">{{ popup.settings.customValues.playername }}</span>
      </div>
    </div>
  </div>

  <div class="modal-body p-0">
    <div>
      <span *ngIf="!isSuccessSaved.workgroup" class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
    </div>
    <div class="row">
      <!-- Not implemented -->
      <div class="col-md-6 col-sm-12 form-group pr-md-3">
        <label for="playername">
          {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.PLAYERNAME.LABEL' | translate }}
        </label>
        <input
          type="text"
          id="playername"
          [disabled]="true"
          class="form-control form-control-sm"
          placeholder="{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.PLAYERNAME.PLACEHOLDER' | translate }}"
        >
        <div *ngIf="isLoading.playername || hasError.playername" class="loading-popup">
          <div *ngIf="isLoading.playername" class="loading"></div>
          <div *ngIf="hasError.playername" class="text-white bg-danger p-2">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 row no-gutters" formGroupName="workgroup">
        <div class="col-7 pr-3 form-group">
          <label class="w-100" for="workgroups">
            {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.NAME' | translate }}
          </label>
          <ng-select
            name="selectedWorkgroup"
            placeholder="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SELECT' | translate }}"
            [items]="workgroupsList || []"
            formControlName="selectedWorkgroup"
            (change)="form.get('workgroup')?.markAsTouched()"
            [clearable]="false"
          ></ng-select>
        </div>
        <div class="col-5 form-group">
          <label class="w-100" for="lang">
            {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.LANGUAGE' | translate }}
          </label>
          <ng-select
            name="selectedlang"
            placeholder="{{ 'ADMIN_PAGES.CLIENTS.DETAILS.SELECT' | translate }}"
            [items]="availableLanguagesList || []"
            formControlName="selectedlang"
            (change)="form.get('workgroup')?.markAsTouched()"
            [disabled]="!(form.get('workgroup')?.get('selectedWorkgroup')?.value)"
            [clearable]="false"
            [searchable]="false"
          ></ng-select>
          <div *ngIf="form?.get('workgroup')?.get('selectedlang')?.errors && isSubmitted" class="text-danger form-field-error-message">
            <span *ngIf="form?.get('workgroup')?.get('selectedlang')?.errors?.required">Language is required</span>
          </div>
        </div>
        <div *ngIf="isLoading.workgroup || hasError.workgroup" class="loading-popup">
          <div *ngIf="isLoading.workgroup" class="loading"></div>
          <div *ngIf="hasError.workgroup" class="text-danger bg-white">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="position-relative" formGroupName="bandwidth">
      <div>
        <span *ngIf="!isSuccessSaved.throttling" class="badge badge-danger">{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_ERROR' | translate }}</span>
      </div>
      <div class="d-flex pt-3 pb-4">
        <mat-slide-toggle [(ngModel)]="isBandwidthSettingsEnabled" name="bandwidthSettings" [ngModelOptions]="{standalone: true}" (change)="form.get('bandwidth')?.markAsTouched()">
          <span class="font-weight-lighter">{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.CHECKBOX_TEXT'  | translate }}</span>
        </mat-slide-toggle>
        <div
          class="ml-1 d-inline-flex align-items-center"
          matTooltip="{{ 'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.INFO' | translate }}"
        >
          <img src="assets/icons/info.svg" alt="information">
        </div>
      </div>
      <hr *ngIf="isBandwidthSettingsEnabled">
      <div *ngIf="isBandwidthSettingsEnabled" class="mt-4">
        <div class="form-row">
          <div class="form-group col-12 col-md-3">
            <label for="bandwidthLimit">
              {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.LIMIT'  | translate }}
            </label>
            <input
              id="bandwidthLimit"
              formControlName="bandwidthLimit"
              type="number"
              min="10000"
              class="form-control form-control-sm"
              placeholder="Limit in b/s"
            />
            <div *ngIf="form?.get('bandwidth')?.get('bandwidthLimit')?.errors" class="text-danger form-field-error-message">
              <span *ngIf="form?.get('bandwidth')?.get('bandwidthLimit')?.errors?.required">Bandwidth Limit is required</span>
              <span *ngIf="form?.get('bandwidth')?.get('bandwidthLimit')?.errors?.min">Bandwidth Limit must be higher than 10000</span>
            </div>
          </div>
          <div class="col-12 col-md-3 pl-md-3 mb-3 mb-md-0" style="margin-top: 37px;">
            <a (click)="resetBandwidth()" class="primary">
              <img src="assets/icons/undo.svg" alt="reverse" class="mb-1 mr-1">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.CONTENT_THROTTLING_RESET' | translate }}
            </a>
          </div>

          <div class="col-6 col-md-3 form-group">
            <label for="starttime">
              {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.START_TIME'  | translate }}
            </label>
            <input type="time" id="starttime" formControlName="startTime" name="starttime" class="form-control form-control-sm">
          </div>
          <div class="col-6 col-md-3 form-group">
            <label for="endtime">
              {{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.END_TIME'  | translate }}
            </label>
            <input type="time" id="endtime" formControlName="endTime" name="endtime" class="form-control form-control-sm">
          </div>
        </div>
        <div class="d-flex" style="color: #787878; width: 30%">
          <img class="mr-2 pb-3" src="assets/icons/info.svg" alt="information">
          <span class="pt-2">{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.BANDWIDTH.INFO'  | translate }}</span>
        </div>
      </div>
      <div *ngIf="isLoading.throttling || hasError.throttling" class="loading-popup">
        <div *ngIf="isLoading.throttling" class="loading"></div>
        <div *ngIf="hasError.throttling" class="text-danger bg-white">{{ 'ADMIN_PAGES.CLIENTS.DETAILS.LOADING_ERROR' | translate }}</div>
      </div>
    </div>
  </div>
  <hr style="margin-top: 38px; margin-bottom: 30px; margin-left: -44px; margin-right: -44px;">
  <div class="modal-footer border-0 p-0">
    <span
      *ngIf="hasNothingChangedOnSave"
      class="text-danger mr-2"
    >{{ 'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.NO_CHANGES' | translate }}</span>
    <button type="button" class="btn btn-secondary" (click)="closePopup()">{{ 'ADMIN_PAGES.CANCEL' | translate }}</button>
    <button
      type="button"
      (click)="save()"
      [disabled]="isLoading.throttling || isLoading.playername || isLoading.workgroup"
      class="btn btn-primary ml-3"
      >{{ 'ADMIN_PAGES.SAVE' | translate }}
    </button>
  </div>
</form>
