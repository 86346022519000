import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-player-restart',
  templateUrl: './player-restart.component.html',
  styleUrls: ['./player-restart.component.scss'],
})
export class PopupPlayerRestartComponent {
  public popup?: IPopup;
  public playerRestarting = false;

  constructor(
    private logger: NGXLogger,
    private orgaentitiesService: OrgaentitiesService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) {}

  public closeOverlay(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  public restartPlayer(): void {
    this.playerRestarting = true;
    this.orgaentitiesService.rebootDevice(String(this.popup?.settings.customValues?.playername)).then(() => {
      this.playerRestarting = false;
      if (this.popup?.settings.customValues?.playername) {
        this.closeOverlay();
        this.toastr.success(
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.PLAYER'))
            + this.popup.settings.customValues.playername
            + String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.RESTARTED_SUCCESS')),
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.RESTARTED_SUCCESS_TITLE')),
        );
      }
    }).catch((err) => {
      this.playerRestarting = false;
      this.toastr.error(
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.RESTARTED_FAILED')),
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.DETAILS.RESTARTED_FAILED_TITLE')),
      );
      this.logger.debug(err);
    });
  }
}
