<div class="content-block content-block-padding content-block-height">
  <h1 class="mb-4">{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE_GENERATOR' | translate }}</h1>
  <form>
    <div class="row pt-1">
      <div class="col-sm-6">
        <div class="mb-3">
          <p>
            {{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.VIN_FIN' | translate }}
            <span
              matTooltip="{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.INFO' | translate }}"
            >
              <img src="assets/icons/info.svg" class="info-icon" alt="information"/>
            </span>
          </p>
          <input
            type="text"
            [(ngModel)]="vehicleIdentificationNumber"
            name="vehicleIdentificationNumber"
            class="form-control col-12"
          />
        </div>
        <div class="mb-3 pt-1">
          <p>
            {{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.GSSN_VF' | translate }}
          </p>
          <ng-select
            *ngIf="selectLocations"
            [items]="selectLocations"
            [(ngModel)]="gssn"
            name="gssn"
            class="col-12"
            [clearable]="false"
          >
          </ng-select>
          <div class="loading" *ngIf="isLoading"></div>
        </div>

        <div class="button-container">
          <button type="button" class="btn btn-secondary mr-3" [routerLink]="['/connectedshowroom']">{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.CANCEL' | translate }}</button>
          <button type="submit" class="btn btn-primary" (click)="generateQrCode()" [disabled]="!vehicleIdentificationNumber || !gssn || isQrGenerating">{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.GENERATE' | translate }}</button>
        </div>
      </div>
      <div class="col-sm-6">
        <p>
          {{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.QR_CODE' | translate }}
        </p>
        <div class="qr-code">
          <img *ngIf="qrCodeImageSource" height="174px" [src]="qrCodeImageSource" />
          <div class="loading" *ngIf="isQrGenerating"></div>
        </div>
        <button *ngIf="qrCodeImageSource" type="button" class="btn btn-primary download" (click)="downloadQrCode(this.qrCodeImageSource, 'QR Code ' + generatedVehicleIdentificationNumber + '.png')">{{ 'ADMIN_PAGES.CONNECTEDSHOWROOM.QR_CODE.DOWNLOAD' | translate }}</button>
      </div>
    </div>
  </form>
</div>
