import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  IOrgaentityLocationContact,
  ContactType,
  IOrgaentityClient,
  IOrgaentityAll,
} from '@dep/common/interfaces';
import { IEnergySettings, IDeviceAttributes } from '@dep/common/interfaces/device-api/device-attributes.interface';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { DeviceSettingsService, IBandwidthSettings } from '@dep/frontend/services/device-settings.service';
import { OrgaentitiesService } from '@dep/frontend/services/orgaentities.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

enum EnergyManagementLocationDialogStep {
  PlayersSelection,
  EnergySettings,
}

@Component({
  selector: 'app-popup-energy-management',
  templateUrl: './energy-management.component.html',
  styleUrls: ['./energy-management.component.scss'],
})
export class PopupEnergyManagementComponent implements OnInit {
  public readonly energyManagementLocationDialogStep = EnergyManagementLocationDialogStep;
  public popup?: IPopup;

  public isLoading = {
    energySettings: false,
    throttling: false,
    contacts: false,
    clients: false,
  };

  public hasError = { energySettings: false, throttling: false, contacts: false };
  public isSuccessSaved = { energySettings: true, throttling: true };
  public isEnergySettingsPending = false;
  public isActiveHours = false;
  public weekDaysSelected: string[] = [];
  public isContentThrottling?: boolean;
  public currentStep = EnergyManagementLocationDialogStep.PlayersSelection;
  public isNextButtonDisabled = true;
  public areAllClientsSelected = false;

  public isFormDisabled = false;
  public playerRestarting = false;
  public restart = false;
  public playerRestartSuccess = false;

  public contacts: IOrgaentityLocationContact[] = [
    {
      name: '',
      mail: '',
      phone: '',
      contactType: ContactType.TECHNICAL,
    },
    {
      name: '',
      mail: '',
      phone: '',
      contactType: ContactType.COMMERCIAL,
    },
  ];

  public energySettings: IEnergySettings = {
    dailyReboot: {
      enabled: true,
      hour: 0,
      minute: 0,
    },
    activeHours: {
      enabled: false,
      settings: [],
    },
  };

  public clients: { client: IOrgaentityClient; selected: boolean; }[] = [];

  public dailyRebootSelectValue = '04:30';
  public activeHoursSelectValue: { days: string[]; start: string; end: string; }[] = [];
  public deviceSettings?: IDeviceAttributes;

  constructor(
    private logger: NGXLogger,
    private orgaentitiesService: OrgaentitiesService,
    private deviceSettingsService: DeviceSettingsService,
    private cd: ChangeDetectorRef,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) {}

  public ngOnInit(): void {
    if (this.popup?.settings.customValues?.type === 'CLIENT') {
      this.loadDeviceSettings();
    }
    if (this.popup?.settings.customValues?.type === 'LOCATION') {
      // Location-level energy management does not have a toggle. Active hours are enabled by default.
      this.isActiveHours = true;

      this.loadClients();
    }
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }

  public async loadDeviceSettings(): Promise<void> {
    this.isLoading.energySettings = true;
    this.isLoading.throttling = true;
    try {
      const bandwidth = await this.deviceSettingsService.getBandwidth(String(this.popup?.settings.customValues?.playername));
      if (bandwidth === undefined) {
        throw new Error('Bandwidth limit undefined');
      }

      if (bandwidth.setting) {
        this.isContentThrottling = !this.deviceSettingsService.isBandwidthLimitDisabled(bandwidth.setting);
      }
      this.isLoading.throttling = false;
    } catch (error) {
      this.logger.error(error);
      this.isLoading.throttling = false;
      this.hasError.throttling = true;
    }

    try {
      this.deviceSettings = await this.deviceSettingsService.getDeviceAttributes(String(this.popup?.settings.customValues?.playername), 'properties');
      this.logger.info('Updated default settings with current device settings.');
      this.initEnergySettings();
      this.isLoading.energySettings = false;
    } catch (error) {
      this.logger.error(error);
      this.isLoading.energySettings = false;
      this.hasError.energySettings = true;
    }
  }

  /**
   * Get clients tree, filter all clients, and add `selected` property to every client.
   */
  public async loadClients(): Promise<void> {
    this.isLoading.clients = true;
    const tree = await this.orgaentitiesService.getClientsTree(Number(this.popup?.settings.customValues?.orgaentityId));

    if (tree.items[0].children) {
      const clients = tree.items[0].children.filter(
        (item: IOrgaentityAll) => item.type === 'CLIENT',
      );

      this.clients = clients.map((player) => ({
        client: player as IOrgaentityClient,
        selected: false,
      }));
    }

    this.isLoading.clients = false;
  }

  public selectAllClients(): void {
    this.areAllClientsSelected = !this.areAllClientsSelected;
    this.clients.forEach((client) => {
      client.selected = this.areAllClientsSelected;
    });
    this.isNextButtonDisabled = this.clients.every((client) => !client.selected);
  }

  public selectClient(client: { client: IOrgaentityClient; selected: boolean; }): void {
    client.selected = !client.selected;
    this.areAllClientsSelected = this.clients.every((selectedClient) => selectedClient.selected);
    this.isNextButtonDisabled = this.clients.every((selectedClient) => !selectedClient.selected);
  }

  /**
   * Proceed from `PlayerSelection` to `EnergySettings` and reset `isContentThrottling` and `activeHoursSelectValue`.
   * Called when "next" button is clicked.
   */
  public proceedToEnergySettingsStep(): void {
    this.currentStep = EnergyManagementLocationDialogStep.EnergySettings;
    this.isContentThrottling = false;
    this.activeHoursSelectValue = [];
  }

  /**
   * Checks if the device settings of a player are pending by compared the desired
   * and reported states of `activeHours` and `dailyReboot`.
   *
   * @param deviceSettings - Device settings which will be compared
   * @returns Pending state
   */
  private areDeviceSettingsPending(deviceSettings: IDeviceAttributes): boolean {
    // If reported and desired are not set -> no changes pending.
    if (!deviceSettings.reported?.energySettings && !deviceSettings.desired?.energySettings) {
      return false;
    }
    // If either reported or desired is set -> changes are pending.
    if (!deviceSettings.reported?.energySettings || !deviceSettings.desired?.energySettings) {
      return true;
    }

    const activeHoursPending = JSON.stringify(deviceSettings.desired.energySettings.activeHours)
      !== JSON.stringify(deviceSettings.reported.energySettings.activeHours);
    const dailyRebootPending = JSON.stringify(deviceSettings.desired.energySettings.dailyReboot)
      !== JSON.stringify(deviceSettings.reported.energySettings.dailyReboot);

    this.logger.debug('Device settings pending check: activeHours:', activeHoursPending, 'dailyReboot:', dailyRebootPending);

    return activeHoursPending || dailyRebootPending;
  }

  public initEnergySettings(): void {
    this.energySettings = this.deviceSettings?.desired?.energySettings || this.energySettings;

    if (this.deviceSettings && this.deviceSettings.desired && this.deviceSettings.reported) {
      this.isEnergySettingsPending = this.areDeviceSettingsPending(this.deviceSettings);
    }

    this.isActiveHours = this.energySettings.activeHours.enabled || false;
    this.activeHoursSelectValue = [];

    // map device settings of dailyReboot to model
    if (this.energySettings.dailyReboot) {
      const hourValue = `${this.energySettings.dailyReboot.hour}`.length === 1
        ? `0${this.energySettings.dailyReboot.hour}` : `${this.energySettings.dailyReboot.hour}`;
      const minuteValue = `${this.energySettings.dailyReboot.minute}`.length === 1
        ? `0${this.energySettings.dailyReboot.minute}` : `${this.energySettings.dailyReboot.minute}`;
      this.dailyRebootSelectValue = `${hourValue}:${minuteValue}`;
    }

    // map device settings of activeHours to model
    if (this.energySettings.activeHours.settings) {
      for (const activeHourSetting of this.energySettings.activeHours.settings) {
        const startHourValue = `${activeHourSetting.start.hour}`.length === 1 ? `0${activeHourSetting.start.hour}` : `${activeHourSetting.start.hour}`;
        const startMinuteValue = `${activeHourSetting.start.minute}`.length === 1 ? `0${activeHourSetting.start.minute}` : `${activeHourSetting.start.minute}`;
        const endHourValue = `${activeHourSetting.end.hour}`.length === 1 ? `0${activeHourSetting.end.hour}` : `${activeHourSetting.end.hour}`;
        const endMinuteValue = `${activeHourSetting.end.minute}`.length === 1 ? `0${activeHourSetting.end.minute}` : `${activeHourSetting.end.minute}`;
        this.activeHoursSelectValue.push({
          days: activeHourSetting.days,
          start: `${startHourValue}:${startMinuteValue}`,
          end: `${endHourValue}:${endMinuteValue}`,
        });
      }
    }
  }

  /**
   * Handle changes of weekdays in activity periods.
   */
  public onActiveHoursWeekdayChange(
    activeHoursSetting: { days: string[], start: string, end: string },
    event: Event,
  ): void {
    const eventTarget = event.target as HTMLInputElement;
    if (eventTarget.checked && !activeHoursSetting.days.includes(eventTarget.value)) {
      activeHoursSetting.days.push(eventTarget.value);
    } else if (!eventTarget.checked && activeHoursSetting.days.includes(eventTarget.value)) {
      activeHoursSetting.days = activeHoursSetting.days.filter((day) => day !== eventTarget.value);
    }

    this.cd.detectChanges();
  }

  /**
   * Add a new row to the activity period.
   */
  public addTimeSelectionRow(): void {
    this.activeHoursSelectValue = [...this.activeHoursSelectValue, {
      days: [],
      start: '08:00',
      end: '16:30',
    }];

    this.cd.detectChanges();
  }

  /**
   * Remove a row from activity period.
   */
  public removeTimeSelectionRow(
    activeHoursSetting: { days: string[]; start: string; end: string; },
  ): void {
    this.activeHoursSelectValue = this.activeHoursSelectValue.filter((item) => item !== activeHoursSetting);
  }

  /**
   * Send energy settings to backend.
   */
  public async saveData(form?: UntypedFormGroup): Promise<void> {
    // Special case: If no energy settings are provided in LOCATION mode, `isActiveHours` is still `true` (because there is no toggle input).
    // This needs to be corrected here by setting `isActiveHours` to `false` if no active hours are set (empty array).
    if (this.popup?.settings.customValues?.type === 'LOCATION' && this.activeHoursSelectValue.length === 0) {
      this.isActiveHours = false;
    }

    // Set enabled statuses according to toggle input.
    this.energySettings.dailyReboot.enabled = !this.isActiveHours;
    this.energySettings.activeHours.enabled = this.isActiveHours;

    // Set settings according to toggle input.
    // Do not reset settings of disabled input.
    if (this.isActiveHours) {
      this.energySettings.activeHours.settings = [];

      // map selected ActiveHours settings into energy settings
      for (const obj of this.activeHoursSelectValue) {
        this.energySettings.activeHours.settings.push({
          days: obj.days,
          start: { hour: Number(obj.start.slice(0, 2)), minute: Number(obj.start.slice(3)) },
          end: { hour: Number(obj.end.slice(0, 2)), minute: Number(obj.end.slice(3)) },
        });
      }
    } else {
      this.energySettings.dailyReboot.hour = Number(this.dailyRebootSelectValue.slice(0, 2) || -1);
      this.energySettings.dailyReboot.minute = Number(this.dailyRebootSelectValue.slice(3) || -1);
    }

    this.logger.debug('Updating Device Settings', this.popup?.settings.customValues?.playername, this.energySettings);

    // Send new settings to backend.
    let response: any;
    const promises = [];
    if (!this.hasError.energySettings) {
      try {
        this.isLoading.energySettings = true;

        if (this.popup?.settings.customValues?.type === 'CLIENT') {
          await this.updateEnergySettings(String(this.popup.settings.customValues.playername));
          this.logger.info(`Energy Management: success. (Response: ${JSON.stringify(response)}`);
          if (JSON.stringify(this.deviceSettings?.desired) !== JSON.stringify(this.deviceSettings?.reported)) {
            this.toastr.warning(
              String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_PENDING')),
              String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_PENDING_TITLE')),
            );
          }
        }

        // When saving the energy settings for multiple players, update each player (parallel execution).
        if (this.popup?.settings.customValues?.type === 'LOCATION') {
          const selectedClients = this.clients.filter((client) => client.selected);
          for (const client of selectedClients) {
            promises.push(this.updateEnergySettings(String(client.client.playername)));
          }
          await Promise.all(promises);
        }
      } catch (e) {
        this.isLoading.energySettings = false;
        this.isSuccessSaved.energySettings = false;
        this.isEnergySettingsPending = false;
        this.logger.error('Updating device settings failed', e);
      }
    } else {
      this.logger.warn('Will not send device settings API call', !this.hasError.energySettings);
    }

    if (!this.hasError.throttling && form?.get('contentThrottling')?.touched) {
      try {
        this.isLoading.throttling = true;
        const bandwidth: IBandwidthSettings = this.isContentThrottling
          ? { startTime: '06:00', endTime: '20:00', bandwidthLimit: 10000 }
          : { startTime: '00:00', endTime: '00:01', bandwidthLimit: 1000000000 };

        if (this.popup?.settings.customValues?.type === 'CLIENT') {
          response = await this.deviceSettingsService.updateBandwidth(String(this.popup.settings.customValues.playername), bandwidth);
          this.logger.info('Energy Management updated bandwidth: success', response);
        }

        // Update bandwidth for multiple players (parallel execution).
        if (this.popup?.settings.customValues?.type === 'LOCATION') {
          const selectedClients = this.clients.filter((client) => client.selected);
          for (const client of selectedClients) {
            response = this.deviceSettingsService.updateBandwidth(String(client.client.playername), bandwidth);
            promises.push(response);
            this.logger.info('Energy Management updated bandwidth: success', response);
          }
          await Promise.all(promises);
        }
        this.isLoading.throttling = false;
      } catch (e) {
        this.isLoading.throttling = false;
        this.isSuccessSaved.throttling = false;
        this.logger.error('Updating device settings failed', e);
      }
    } else {
      this.logger.warn('Will not send bandwidth API call', !this.hasError.throttling, form?.get('contentThrottling')?.touched);
    }

    if (!this.isSuccessSaved.throttling || !this.isSuccessSaved.energySettings) {
      this.toastr.error(
        String(this.ngxTranslate.instant(
          'ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_FAILED',
          { name: this.popup?.settings.customValues?.name },
        ))
          + '<br /><small>'
          + String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_FAILED_EXPLANATION'))
          + '</small>',
        String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_FAILED_TITLE')),
      );
    } else {
      // If everything has been saved successfully, close the popup and show the success message based on selected clients amount and popup type.
      this.closePopup();

      // If multiple items were saved, a different message needs to be shown.
      const selectedClientCount = this.clients.filter((obj) => obj.selected === true);
      if (selectedClientCount.length > 1) {
        this.toastr.success(
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_SUCCESS_MULTIPLE', { amount: selectedClientCount.length })),
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_SUCCESS_TITLE')),
        );
      } else {
        this.toastr.success(
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_SUCCESS', { name: this.popup?.settings.customValues?.name })),
          String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.ENERGY_MANAGEMENT_MODAL.SAVE_SUCCESS_TITLE')),
        );
      }
    }
  }

  private async updateEnergySettings(playername: string): Promise<void> {
    this.deviceSettings = await this.orgaentitiesService.updateDeviceSettings(
      String(playername),
      this.energySettings,
    );
  }

  public weekdayConfigured(activeHoursSetting: { days: string[]; start: string; end: string; }, weekday: string): boolean {
    // Check if weekday is configured in current row.
    let isNotConfigured = !activeHoursSetting.days.includes(weekday);

    // Check if weekday is configured in another row.
    if (isNotConfigured) {
      isNotConfigured = !!this.activeHoursSelectValue.filter((item) => item.days.includes(weekday)).length;
    }

    return isNotConfigured;
  }

  /**
   * Check if required data is set.
   * (Used to disable the "Save" button if `true`.)
   */
  public reqInputIncomplete(): boolean {
    for (const row of this.activeHoursSelectValue) {
      if (!row.days.length || !row.start.length || !row.end.length) {
        return true;
      }
    }
    return false;
  }
}
