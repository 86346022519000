import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IRCPSWorkgroup } from '@dep/common/interfaces/device-api/rcps-workgroup.interface';
import { NgOption } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { DeviceSettingsService, IBandwidthSettings } from '@dep/frontend/services/device-settings.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-rcps-settings',
  templateUrl: './rcps-settings.component.html',
  styleUrls: ['./rcps-settings.component.scss'],
})
export class PopupRCPSSettingsComponent implements OnInit {
  public popup?: IPopup;
  public isLoading = { playername: false, throttling: false, workgroup: false };
  public hasError = { playername: false, throttling: false, workgroup: false };
  public isSuccessSaved = { playername: true, throttling: true, workgroup: true };

  public isSubmitted = false;
  public hasNothingChangedOnSave = false;

  public isBandwidthSettingsEnabled?: boolean;
  public form?: UntypedFormGroup;

  public availableLanguagesList?: NgOption[];
  public workgroupsList?: {
    availableLanguages: IRCPSWorkgroup['availableLanguages'];
    value: string;
    label: string;
  }[];

  public bandwidth?: { setting: IBandwidthSettings; workgroupName: string; defaultLanguageCode: string; };

  constructor(
    private logger: NGXLogger,
    private fb: UntypedFormBuilder,
    private deviceSettingsService: DeviceSettingsService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private ngxTranslate: TranslateService,
  ) {
    this.form = this.fb.group({
      bandwidth: this.fb.group({
        startTime: [undefined, Validators.required],
        endTime: [undefined, Validators.required],
        bandwidthLimit: [undefined, [Validators.required, Validators.min(0)]],
      }),
      workgroup: this.fb.group({
        selectedWorkgroup: [undefined],
        selectedlang: [undefined, Validators.required],
      }),
    });
  }

  public async ngOnInit(): Promise<void> {
    this.logger.debug(`RcpsBandwidthSettingsModal: Init for playername ${String(this.popup?.settings.customValues?.playername)}`);
    if (this.popup?.settings.customValues?.playername) {
      this.loadDeviceSettings();
    }
  }

  public async loadDeviceSettings(): Promise<void> {
    try {
      this.isLoading = { playername: false, throttling: true, workgroup: true };
      this.workgroupsList = (await this.deviceSettingsService.getAllWorkgroups()).map((workgroup) => ({
        availableLanguages: workgroup.availableLanguages,
        value: workgroup.name,
        label: workgroup.name,
      }));

      this.bandwidth = await this.deviceSettingsService.getBandwidth(this.popup?.settings.customValues?.playername || '');

      const workgroup = this.workgroupsList.find((group) => group.label === this.bandwidth?.workgroupName);
      this.availableLanguagesList = workgroup?.availableLanguages.map((lang) => ({
        value: lang.languageCode,
        label: lang.displayName,
      })) || [];

      if (this.bandwidth === undefined) {
        throw new Error('Bandwidth limit undefined');
      }
      this.isBandwidthSettingsEnabled = !this.deviceSettingsService.isBandwidthLimitDisabled(this.bandwidth.setting);
      this.isLoading = { playername: false, throttling: false, workgroup: false };
    } catch (error: any) {
      this.logger.error('RcpsBandwidthSettingsModal: OnInit error', error?.message);
      this.isLoading = { playername: false, throttling: false, workgroup: false };
      this.hasError = { playername: true, throttling: true, workgroup: true };
    }
    this.initBandwidthSettings();
  }

  public initBandwidthSettings(): void {
    this.form?.get('workgroup')?.get('selectedWorkgroup')?.valueChanges.subscribe((workgroupValue: any) => {
      if (workgroupValue) {
        this.form?.get('workgroup')?.get('selectedlang')?.enable();
        this.form?.get('workgroup')?.get('selectedlang')?.reset();
      } else {
        this.form?.get('workgroup')?.get('selectedlang')?.disable();
      }
    });
    const initValues = {
      bandwidth: {
        startTime: this.bandwidth?.setting.startTime,
        endTime: this.bandwidth?.setting.endTime,
        bandwidthLimit: this.bandwidth?.setting.bandwidthLimit,
      },
      workgroup: {
        selectedWorkgroup: this.bandwidth?.workgroupName,
        selectedlang: this.bandwidth?.defaultLanguageCode,
      },
    };
    this.form?.patchValue(initValues);
  }

  // default BandwidthLimit setting: 06:00-20:00,10000
  public resetBandwidth(): void {
    const initValues = {
      bandwidth: {
        startTime: '06:00',
        endTime: '20:00',
        bandwidthLimit: 10000,
      },
    };
    this.form?.patchValue(initValues);
  }

  public async save(): Promise<void> {
    this.logger.debug('RcpsBandwidthSettingsModal: Save', this.form);
    this.isSuccessSaved = { playername: true, throttling: true, workgroup: true };
    this.isSubmitted = true;
    this.hasNothingChangedOnSave = false;
    if (!this.form || this.form.invalid) {
      return;
    }

    let hasNothingChangedOnSave = true;

    if (this.popup?.settings.customValues?.playername) {
      if (this.form.get('bandwidth')?.valid && this.form.get('bandwidth')?.touched) {
        hasNothingChangedOnSave = false;
        this.isLoading.throttling = true;
        try {
          let bandwidthSettings: IBandwidthSettings | undefined;

          // If bandwidth limit is disabled, use disabled default values.
          if (!this.isBandwidthSettingsEnabled) {
            bandwidthSettings = { startTime: '00:00', endTime: '00:01', bandwidthLimit: 1000000000 };
          } else {
            bandwidthSettings = this.form.get('bandwidth')?.value;
          }

          if (bandwidthSettings && bandwidthSettings.startTime && bandwidthSettings.endTime && bandwidthSettings.bandwidthLimit) {
            await this.deviceSettingsService.updateBandwidth(this.popup.settings.customValues.playername, bandwidthSettings);
            this.isLoading.throttling = false;
          } else {
            throw new Error('Invalid form bandwidth value');
          }
          this.toastr.success(
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_SUCCESS', { name: this.popup.settings.customValues.name })),
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_SUCCESS_TITLE')),
          );
        } catch (error: any) {
          this.logger.error('RcpsBandwidthSettingsModal: Save error updateBandwidth', error?.message);
          this.toastr.error(
            String(this.ngxTranslate.instant(
              'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_FAILED',
              { name: this.popup.settings.customValues.name },
            )) + '<br /><small>' + error.message + '</small>',
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.SAVE_FAILED_TITLE')),
          );
          this.isLoading.throttling = false;
          this.isSuccessSaved.throttling = false;
        }
      }

      if (this.form.get('workgroup')?.valid && this.form.get('workgroup')?.touched) {
        hasNothingChangedOnSave = false;
        this.isLoading.workgroup = true;
        try {
          await this.deviceSettingsService.updateWorkgroup(
            this.popup.settings.customValues.playername,
            {
              defaultLanguageCode: this.form.get('workgroup')?.get('selectedlang')?.value,
              targetWorkgroupName: this.form.get('workgroup')?.get('selectedWorkgroup')?.value,
            },
          );
          this.toastr.success(
            String(this.ngxTranslate.instant(
              'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_SUCCESS',
              { playername: this.popup.settings.customValues.playername },
            )),
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_SUCCESS_TITLE')),
          );
          this.isLoading.workgroup = false;
        } catch (error: any) {
          this.logger.error('RcpsBandwidthSettingsModal: Save error updateWorkgroup', error?.message);
          this.toastr.error(
            String(this.ngxTranslate.instant(
              'ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_FAILED',
              { playername: this.popup.settings.customValues.playername },
            )) + '<br /><small>' + error.message + '</small>',
            String(this.ngxTranslate.instant('ADMIN_PAGES.CLIENTS.RCPS_SETTINGS_MODAL.WORKGROUP.CHANGE_FAILED_TITLE')),
          );
          this.isLoading.workgroup = false;
          this.isSuccessSaved.workgroup = false;
        }
      }
    }

    this.hasNothingChangedOnSave = hasNothingChangedOnSave;
    if (this.isSuccessSaved.playername && this.isSuccessSaved.throttling && this.isSuccessSaved.workgroup) {
      this.closePopup();
    }
  }

  public closePopup(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    }
  }
}
