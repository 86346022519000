[
  {
    "name": "SHOWROOMCONNECT",
    "label": "Showroom:Connect",
    "inputs": [
      {
        "name": "validUntil",
        "type": "datetime"
      }
    ]
  }
]
