import { Component, OnInit } from '@angular/core';
import {
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';

import { FocusModeService } from './shared/services/focus-mode.service';

import { CookieService } from '@dep/frontend/services/cookie.service';
import { NavHistoryService } from '@dep/frontend/services/navhistory.service';
import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public title = 'mercedes-benz-dep';
  public popups$: Subject<IPopup[]>;
  public isAuthGuardPending = false;
  /** If `true`, the current route starts with `/login`. Needed to provide a different background. */
  public isCurrentRouteLogin = false;
  public isFocusModeActive = false;

  constructor(
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService,
    private readonly navHistory: NavHistoryService,
    private readonly cookie: CookieService,
    private readonly popupService: PopupService,
    private readonly router: Router,
    private readonly focusModeService: FocusModeService,
  ) {
    this.translate.setDefaultLang('en');

    this.translate.use('en');

    this.navHistory.loadRouting();

    this.router.events.subscribe((event) => {
      if (event instanceof GuardsCheckStart) {
        this.isAuthGuardPending = true;
      }
      if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
        this.isAuthGuardPending = false;
      }
    });

    this.popups$ = this.popupService.popups$;

    this.focusModeService.focusModeSubject.subscribe((isFocusModeActive: boolean) => {
      this.isFocusModeActive = isFocusModeActive;
    });
  }

  public async ngOnInit(): Promise<void> {
    // Support changing the loglevel on the fly via local storage.
    const logLevelOverride = localStorage.getItem('loglevel');
    if (logLevelOverride) {
      this.logger.log('Log level changed via query parameter', logLevelOverride);

      // Update ngx-logger configuration.
      const config = this.logger.getConfigSnapshot();
      config.level = Number(logLevelOverride);
      this.logger.updateConfig(config);
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const isCurrentRouteLogin = event.url.startsWith('/login') && !event.url.startsWith('/login/register');
        // If the user navigated from or to a login route, update the variable and add or remove the `login-background` class.
        if (isCurrentRouteLogin !== this.isCurrentRouteLogin) {
          this.isCurrentRouteLogin = isCurrentRouteLogin;
          if (this.isCurrentRouteLogin) {
            document.body.classList.add('login-background');
          } else {
            document.body.classList.remove('login-background');
          }
        }
      }
    });
    await this.cookie.init();
  }
}
